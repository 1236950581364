import React, { Component } from 'react'
import {Row,Col,Card, Container} from 'react-bootstrap'
import LightSpeed from 'react-reveal/LightSpeed';
import {BsFillArrowUpRightSquareFill} from 'react-icons/bs'
import {MdUpcoming} from 'react-icons/md'
import '../../Styles/r&d.css'
import Navbar from '../Navbar'
import ReactOwlCarousel from 'react-owl-carousel'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import rd1 from '../../Images/rd1.webp'
import rd2 from '../../Images/rd2.webp'
import rd3 from '../../Images/rd3.webp'
import video2 from '../../Images/denial.mp4'
import {BiVideo} from 'react-icons/bi'
import HoverVideoPlayer from 'react-hover-video-player'
import {Link} from 'react-router-dom'
import rb1 from '../../Images/p2.png'
import rb2 from '../../Images/ra.png'
import infor from '../../Images/evidence.svg'
import rd from '../../Images/rd.PNG'
import innovation from '../../Images/innovation.PNG'
export class RD extends Component {
  componentDidMount(){
    window.scrollTo(0, 0)
  }
  render() {
    return (
        <div>
          <Navbar color="white" textColor="black" shadow="0 2px 5px rgba(0,0,0,.2)"/>
            <Row style={{margin:'0px'}}>
              <Col md="1"/>
              <Col md="8" className="top-row">
                <h4 className='h-heading'>Creating the next big thing</h4>
                <p className='h-content'>
                    In healthcare automation and exploring new horizons through experimentation and
                    continuous research and development activities.
                </p>
               <Link to='/contact-us' className='link'><p className='h-getdemo'>Get Demo</p></Link> 
              </Col>
              <Col md="3">
              <img src={rd} className='rd-img top-img'/>
              </Col>
          </Row>
          <br/><br/>
          <Row style={{margin:'0px'}}>
              <Col md="1"></Col>
              <Col md="7" sm={12} xs={12}>
              <h4 className='h-heading'>Innovation at each stage</h4>
                <p className='h-content'>
                   OBO Success is based on our ability to create automation in every stage of healthcare process.We invest in a range of emerging technologies and breakthroughs,which we believe will 
                   create significant opportunities to deliver value to our customers and growth for the company.In this fast-growing world, exploring and experimenting with disruptive technologies is 
                   the key to managing technology risk and hence we maintain our long-term committment to research and development across a wide spectrum of technologies,tools and platforms,business problems.
                </p>
              </Col>
              <Col md="1"></Col>
              <Col md="3" xs={12} sm={12}>
                  <img src={innovation} className='right-img'/>
              </Col>
           
          </Row>
          <br/><br/>
          <div style={{backgroundColor:'whitesmoke'}} className='research-area'>
              <br/>
          <h4 className='h-heading' align="center">Research formula</h4>
          <h6 className='rd-productformula' align='center'>A3 = Analysis + Actions + Automation</h6>
          <p className='h-content' align='center'><b>Analysis:</b>&nbsp;&nbsp;Analyse the healthcare automation problem in the markets faced by the user</p>
          <p className='h-content' align='center'><b>Actions:</b>&nbsp;&nbsp;Our Team take action to build AI infused software product around the problem</p>
           <p className='h-content' align='center'><b>Automation:</b>&nbsp;&nbsp;Delivering the product which solve the automation problems in the healthcare market</p>
         <br/>
          </div><br/><br/>
          {/* <h4 className='h-heading' align='center'>Research Paper</h4><br/> */}
          {/* <Container>
          <Row style={{margin:'0px'}} >
            <Col md="4">
            <Card className='h-cards' style={{width:'24rem'}}>
           <Card.Img variant="top" src="https://tateeda.com/wra-content/uploads/2021/08/image-3.5.png"  />
           <Card.Body>
               <Card.Title>Title</Card.Title>
               <p className='card-learnmore'>Learn More&nbsp;&nbsp;<BsFillArrowUpRightSquareFill className='learnmore-icon'/></p>
           </Card.Body>
           </Card>
           </Col>
           <Col md="4">
            <Card className='h-cards' style={{width:'24rem'}}>
           <Card.Img variant="top" src="https://tateeda.com/wra-content/uploads/2021/08/image-3.5.png" />
           <Card.Body>
               <Card.Title>Title</Card.Title>
               <p className='card-learnmore'>Learn More&nbsp;&nbsp;<BsFillArrowUpRightSquareFill className='learnmore-icon'/></p>
           </Card.Body>
           </Card>
           </Col>
           <Col md="4">
            <Card className='h-cards' style={{width:'24rem'}}>
           <Card.Img variant="top" src="https://tateeda.com/wra-content/uploads/2021/08/image-3.5.png" />
           <Card.Body>
               <Card.Title>Title</Card.Title>
               <p className='card-learnmore'>Learn More&nbsp;&nbsp;<BsFillArrowUpRightSquareFill className='learnmore-icon'/></p>
           </Card.Body>
           </Card>
           </Col>
          
        </Row>
        </Container> */}
        <h4 className='h-heading' align='center'>Our Research Area</h4><br/><br/><br/>
        <LightSpeed left>
        <Container>
        
        <Row style={{margin:'0px'}}>
          <Col md="4" xs={12} sm={12}>
          <div className='ra-card'>
            <p className="ra-content">Digital Tools</p>
            <ul>
              <li>GDSS</li>
              <li>Collaborative Systems</li>
              <li>Intelligent Manufacturing systems</li>
              <li>HMIs</li>
            </ul>
         </div>
          </Col>
          <Col md="4" xs={12} sm={12}>
          <div className='ra-card'>
            <p className="ra-content">eHealth</p>
            <ul>
              <li>EMRs</li>
              <li>Patient Behaviour</li>
              <li>Personalized Coaching</li>
              <li>Radiology & Imagining</li>
              <li>Personalized Medicine</li>
              <li>Secure Storage</li>
            </ul>
         </div>
          </Col>
          <Col md="4" xs={12} sm={12}>
          <div className='ra-card'>
            <p className="ra-content">Internet-of-Things</p>
            <ul>
              <li>Iot Edge</li>
              <li>Application Integration</li>
              <li>IoT Device Management</li>
              <li>IoT Security</li>
              <li>IoT Core</li>
            </ul>
         </div>
          </Col>
        
          </Row>
          </Container>
          </LightSpeed>

          <Container>
          <Row>
          <Col md="4" xs={12} sm={12}>
          <div className='ra-card'>
            <p className="ra-content">Artificial Intelligence</p>
            <ul>
              <li>Machine Learning</li>
              <li>Information retrieval</li>
              <li>Recommender Systems</li>
              <li>Knowledge based systems</li>
              <li>Prediction Algorithms</li>
            </ul>
         </div>
          </Col>
          <Col md="4" xs={12} sm={12}>
          <div className='ra-card'>
            <p className="ra-content">Data Analytics</p>
            <ul>
              <li>Big Data</li>
              <li>Data Visualization</li>
              <li>Decision Support Systems</li>
              <li>Cyber-physical system</li>
              <li>Customer analysis</li>
              <li>Data Flow</li>
            </ul>
         </div>
          </Col>
          <Col md="4" xs={12} sm={12}>
          <div className='ra-card'>
            <p className="ra-content">Cloud Computing</p>
            <ul>
              <li>Compute</li>
              <li>High-performance Computing</li>
              <li>Networking,Identity & Security</li>
              <li>Databases</li>
            </ul>
         </div>
          </Col>
          </Row>
        </Container><br/><br/><br/>
        <h4 className='h-heading' align='center'>Research Blogs</h4><br/>
        <Container>
          <Row style={{margin:'0px'}} >
            <Col md="4" xs={12} sm={12}>
            <Card className='h-cards'>
           <Card.Img variant="top" src={rd1} className='f-img' />
           <Card.Body>
               <Card.Title style={{fontWeight:'bold'}}>Prioritizing User Experience in Healthcare Patient Portals</Card.Title>
               <Link to='/blogs/prioritizing-user-experience' className='link'>
               <p className='card-learnmore'>Learn More&nbsp;&nbsp;<BsFillArrowUpRightSquareFill className='learnmore-icon'/></p>
               </Link>
           </Card.Body>
           </Card>
            </Col>
            <Col md="4" xs={12} sm={12}>
            <Card className='h-cards'>
           <Card.Img variant="top" src={rd2} className='f-img'/>
           <Card.Body>
               <Card.Title style={{fontWeight:'bold'}}>The potential for artificial intelligence in healthcare</Card.Title>
               <Link to='/blogs/potential-of-ai' className='link'>
               <p className='card-learnmore'>Learn More&nbsp;&nbsp;<BsFillArrowUpRightSquareFill className='learnmore-icon'/></p>
               </Link>
           </Card.Body>
           </Card>
            </Col>
            <Col md="4" xs={12} sm={12}>
            <Card className='h-cards'>
           <Card.Img variant="top" src={rd3} className='f-img'/>
           <Card.Body>
               <Card.Title style={{fontWeight:'bold'}}>Healthcare Technology Trends and Digital Innovations in 2022</Card.Title>
               <Link to='/blogs/healthcare-technology-trends-and-innovations' className='link'>
               <p className='card-learnmore'>Learn More&nbsp;&nbsp;<BsFillArrowUpRightSquareFill className='learnmore-icon'/></p>
               </Link>
           </Card.Body>
           </Card>
           </Col>
        </Row>
        </Container><br/><br/><br/>
        
        <h4 className='h-heading' align='center'>Evidence Based Life Cycle Information</h4><br></br>
        <Container>
        <Row style={{margin:'0px'}}>
          <Col md="12" xs={12} sm={12}>
            <img src={infor} style={{width:'100%'}}/>
            </Col>
          </Row>
        </Container><br/>
        <br/>
        {/* <h4 className='h-heading' align='center'> AI Benefits</h4><br></br>
        <Container>
        <Row style={{margin:'0px'}}>
          <Col md="12" xs={12} sm={12} >
            <img src={rb1} className='ai-benefit-img'/>
            </Col>
          </Row>
        </Container><br/> */}
        <br/><br/><br/>
        <h4 className='h-heading' align="center">Our Innovation Products</h4>
        <br/><br/>
          <ReactOwlCarousel 
          items={1} 
          className="owl-theme" 
          loop={true}
          nav 
          dots={false}
          autoplay={true}
          autoplayTimeout={3000}
          >
             <div className='rd-carousel'>
            <Container>
            <Row style={{margin:'0px'}}>
          
              <Col md="6">
                 <p className='rd-heading' align='left'>Denial and ARS Platform</p>
                 <p className='rd-content' align="left">Key Features:<br/>1.Predictive Analysis<br/>2.5X speed of claim resolutions<br/>3.Compare with your peers and industry benchmarks</p>
                 <Link to='/products/denial-and-ars-platform' className='link'>
                    <p className='card-learnmore' align="left">Learn More&nbsp;&nbsp;<BsFillArrowUpRightSquareFill className='learnmore-icon'/></p>
                  </Link>
              </Col>
              <Col md="6">
              <HoverVideoPlayer
                // videoSrc={video2}
                className="c-product-video"
                pausedOverlay={
                   <div className="c-product-thumbnail">
                    <p className="c-watch-product" align="center">Watch Our Product&nbsp;&nbsp;<BiVideo className="video-icon"/></p>
                    </div>  
                }
                loadingOverlay={
                    <div className="c-loading-spinner-overlay">Loading...</div>
                }
              
                />
            
              </Col>
              
            </Row>
            </Container>
          </div>
          <div className='rd-carousel'>
          <Container>
            <Row style={{margin:'0px'}}>
              <Col md="6">
                 <p className='rd-heading' align='left'>Radiology</p>
                 <p className='rd-content' align="left">Key Features:<br/>1.Prevent Denials with in-built scrubbing system<br/>2.Developed with latest Machine Learning Algorithms<br/>3.Data Analytics</p>
                 <Link to='/products/radiology' className='link'>
                    <p className='card-learnmore' align="left">Learn More&nbsp;&nbsp;<BsFillArrowUpRightSquareFill className='learnmore-icon'/></p>
                  </Link>
              </Col>
              <Col md="6">
              <HoverVideoPlayer
                //videoSrc={video2}
                className="product-video"
                pausedOverlay={
                   <div className="c-product-thumbnail">
                    <p className="c-watch-product" align="center">Watch Our Product&nbsp;&nbsp;<BiVideo className="video-icon"/></p>
                    </div>  
                }
                loadingOverlay={
                    <div className="c-loading-spinner-overlay">Loading...</div>
                }
              
                />
              </Col>
            </Row>
            </Container>
          </div>
        
         
          <div className='rd-carousel'>
          <Container>
            <Row style={{margin:'0px'}}>
            
              <Col md="6">
                 <p className='rd-heading' align='left'>EHR Product</p>
                 <p className='rd-content' align="left">Complete cloud based application customized for all specialities</p>
                 <Link to='/products/ehr' className='link'><p className='card-learnmore' align="left">Learn More&nbsp;&nbsp;<BsFillArrowUpRightSquareFill className='learnmore-icon'/></p></Link>
              </Col>
              <Col md="6">
              <HoverVideoPlayer
                // videoSrc={video2}
                className="product-video"
                pausedOverlay={
                   <div className="c-product-thumbnail">
                    <p className="c-watch-product" align="center">Watch Our Product&nbsp;&nbsp;<BiVideo className="video-icon"/></p>
                    </div>  
                }
                loadingOverlay={
                    <div className="c-loading-spinner-overlay">Loading...</div>
                }
              
                />
              </Col>
            </Row>
            </Container>
          </div>
          <div className='rd-carousel'>
            <Container>
            <Row style={{margin:'0px'}}>
           
              <Col md="6">
                 <p className='rd-heading' align='left'>E/R,ED</p>
                 <p className='rd-content' align="left">Key Features:<br/>1.Key components for coding ED/ER<br/>2.Addressing the pressing problems<br/>3.In-built with CDR guidelines</p>
                 <Link to='/products/er-ed' className='link'>
                    <p className='card-learnmore' align="left">Learn More&nbsp;&nbsp;<BsFillArrowUpRightSquareFill className='learnmore-icon'/></p>
                  </Link>
              </Col>
              <Col md="6">
              <HoverVideoPlayer
                //videoSrc={video2}
                className="product-video"
                pausedOverlay={
                   <div className="c-product-thumbnail">
                    <p className="c-watch-product" align="center">Watch Our Product&nbsp;&nbsp;<BiVideo className="video-icon"/></p>
                    </div>  
                }
                loadingOverlay={
                    <div className="c-loading-spinner-overlay">Loading...</div>
                }
              
                />
              </Col>
             
            </Row>
            </Container>
          </div>
          <div className='rd-carousel'>
          <Container>
            <Row style={{margin:'0px'}}>
            
              <Col md="6">
                 <p className='rd-heading' align='left'>PMS Product</p>
                 <p className='rd-content' align="left">We are working on the full version of our product and will be launching soon! Stay tuned!</p>
                 <p className='coming-soon' align="left">Coming Soon&nbsp;&nbsp;<MdUpcoming className='coming-icon'/></p>
              </Col>
              <Col md="6">
              <HoverVideoPlayer
                //videoSrc={video2}
                className="product-video"
                pausedOverlay={
                   <div className="c-product-thumbnail">
                    <p className="c-watch-product" align="center">Watch Our Product&nbsp;&nbsp;<BiVideo className="video-icon"/></p>
                    </div>  
                }
                loadingOverlay={
                    <div className="c-loading-spinner-overlay">Loading...</div>
                }
              
                />
              </Col>
            </Row>
            </Container>
          </div>
          </ReactOwlCarousel>
          <Container>
      <h4 className="h-heading" align="center">Empowering to scale, sustain and flourish with our Deep Thinking AI infused solutions</h4>
      <Link to='/contact-us' className='link'><p className='getdemo-center' align="center">Get Demo</p></Link>
      </Container> 
        </div>
    )
  }
}

export default RD