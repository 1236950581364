import React, { Component } from 'react'
import {Row,Col} from 'react-bootstrap'
import '../Styles/Footer.css'
import logo from '../Images/logowhite.PNG'
import {AiFillInstagram,AiFillFacebook,AiOutlineTwitter,AiFillLinkedin,AiOutlineDown} from 'react-icons/ai'
import {BiChevronDown} from 'react-icons/bi'
import { Link } from 'react-router-dom'
import Avatar from 'react-avatar'
let obo=false;
class Footer extends Component {
  constructor(props){
    super(props);
    this.state = {
      obo:false,
      products:false,
      resources:false,
      company:false
    }
  }
  render() {
    return (
      <div>
        <div className='footer-container'>
       <Row style={{margin:'0px'}}>
           <Col md="1"/>
           <Col md="2" xs={12} sm={4}>
              <img src={logo} className='footer-logo'/>

              <p className='footer-content'>(410)999-0303</p>
              <p className='footer-content'>1940,Fountain View Dr.#1250,Houston, Texas 77057,US</p>
           </Col>
           <Col md="2" xs={12} sm={4}>
               <p className='footer-head'>OBO</p>
               <br/>
               <Link to='/services' className='link' style={{color:'white'}} ><p className='footer-content'>Services</p></Link>
               <Link to='/research-and-development' className='link' style={{color:'white'}}><p className='footer-content'>R&D</p></Link>
               <Link to='/about-us' className='link' style={{color:'white'}}><p className='footer-content'>Company</p></Link>
               <br/><br/>
               <div className='footer-follow'>
               <p className='footer-content'>Follow Us On</p>
               <Row style={{margin:'0px',marginLeft:'-20px'}}>
                 <Col md="2">
                 <a href="https://www.linkedin.com/company/obo-health" target="_blank" style={{color:'white',textDecoration:'none'}}><AiFillLinkedin className='linkedin-icon'/></a>
                 </Col>
                 <Col md="2">
                 <a href="https://www.facebook.com/obotechnology" target="_blank" style={{color:'white',textDecoration:'none'}}><AiFillFacebook className='fb-icon'/></a>

                   </Col>
                   <Col md="2">
                   <a href="https://twitter.com/obo_health" target="_blank" style={{color:'white',textDecoration:'none'}}><AiOutlineTwitter className='twitter-icon'/></a>
                   </Col>
                   <Col md="2">
                    <a href="https://www.instagram.com/obohealth" target="_blank" style={{color:'white',textDecoration:'none'}}><AiFillInstagram className='insta-icon'/></a>
                   </Col>
               </Row>
               </div>
            </Col>
            <Col md="2" xs={12} sm={4}>
            <p className='footer-head'>Products</p>
           
          
            <Link to='/products/denial-and-ars-platform' className='link' style={{color:'white'}}><p className='footer-content'>Denial and ARS Platform</p></Link>
            <Link to='/products/radiology' className='link' style={{color:'white'}}><p className='footer-content'>Radiology</p></Link>
            <Link to='/products/ehr' className='link' style={{color:'white'}}><p className='footer-content'>EHR Product</p></Link>
            <Link to='/products/er-ed' className='link' style={{color:'white'}}><p className='footer-content'>ER</p></Link>
            <p>PMS Product</p>
            </Col>
            <Col md="2" xs={12} sm={4}>
            <p className='footer-head'>Resources</p>
            <Link to='/research-and-development' className='link' style={{color:'white'}}><p className='footer-content'>Blogs</p></Link>
            <p className='footer-content'>Webinars</p>
            <p className='footer-content'>Events</p>
            <br/><br/>
            <div className='footer-res'>
               <p className='footer-content'>Follow Us On</p>
               <Row style={{margin:'0px',marginLeft:'-20px'}}>
                 <Col md="2" sm={2}>
                 <a href="https://www.linkedin.com/company/obo-health" target="_blank" style={{color:'white',textDecoration:'none'}}><AiFillLinkedin className='linkedin-icon'/></a>
                 </Col>
                 <Col md="2" sm={2}>
                 <a href="https://www.facebook.com/obotechnology" target="_blank" style={{color:'white',textDecoration:'none'}}><AiFillFacebook className='fb-icon'/></a>

                   </Col>
                   <Col md="2" sm={2}>
                   <a href="https://twitter.com/obo_health" target="_blank" style={{color:'white',textDecoration:'none'}}><AiOutlineTwitter className='twitter-icon'/></a>
                   </Col>
                   <Col md="2" sm={2}>
                    <a href="https://www.instagram.com/obohealth" target="_blank" style={{color:'white',textDecoration:'none'}}><AiFillInstagram className='insta-icon'/></a>
                   </Col>
               </Row>
               </div>
            </Col>
            <Col md="2" xs={12} sm={4}>
           <p className='footer-head'>Company</p>
           <Link to='/about-us' className='link' style={{color:'white'}}><p className='footer-content'>About us</p></Link>
            {/* <Link to='/find-jobs' className='link' style={{color:'white'}}><p className='footer-content'>Careers</p></Link> */}
            <p className='footer-content'>Brand Guidelines</p>
            <p className='footer-content'>Terms of Service</p>
            <p className='footer-content'>Privacy Policy</p>
            </Col>
            

          
            
           <Col md="1"/>
       </Row>
      </div>

      <div className='footer-mob'>
   

    <img src={logo}/><br/>
     
       <p className='dropdown-head'>OBO&nbsp;&nbsp;<BiChevronDown className='dropdown-icon' onClick={()=>{this.setState({obo:!this.state.obo})}}/></p>
      {this.state.obo ?<div>
        <Link to='/services' className='link' style={{color:'white'}} ><p className='dropdown-content'>Services</p></Link>
        <Link to='/research-and-development' className='link' style={{color:'white'}}><p className='dropdown-content'>R&D</p></Link>
        <Link to='/about-us' className='link' style={{color:'white'}}><p className='dropdown-content'>Company</p></Link>
      </div>:null }
      <p className='dropdown-head'>Products&nbsp;&nbsp;<BiChevronDown className='dropdown-icon' onClick={()=>this.setState({products:!this.state.products})}/></p>
      {this.state.products ? <div>
        <Link to='/products/denial-and-ars-platform' className='link' style={{color:'white'}}><p className='dropdown-content'>Denial and ARS Platform</p></Link>
            <Link to='/products/radiology' className='link' style={{color:'white'}}><p className='dropdown-content'>Radiology</p></Link>
            <Link to='/products/ehr' className='link' style={{color:'white'}}><p className='dropdown-content'>EHR Product</p></Link>
            <Link to='/products/er-ed' className='link' style={{color:'white'}}><p className='dropdown-content'>ER</p></Link>
            <p className='dropdown-content'>PMS Product</p>
      </div> : null}
      <p className='dropdown-head'>Resources&nbsp;&nbsp;<BiChevronDown className='dropdown-icon' onClick={()=>this.setState({resources:!this.state.resources})}/></p>
      {this.state.resources===true ? <div>
        <Link to='/research-and-development' className='link' style={{color:'white'}}><p className='dropdown-content'>Blogs</p></Link>
            <p className='dropdown-content'>Webinars</p>
            <p className='dropdown-content'>Events</p>
        </div>:null}
       <p className='dropdown-head'>Company&nbsp;&nbsp;<BiChevronDown className='dropdown-icon' onClick={()=>this.setState({company:!this.state.company})}/></p>
       {this.state.company ? <div>
        <Link to='/about-us' className='link' style={{color:'white'}}><p className='dropdown-content'>About us</p></Link>
            {/* <Link to='/find-jobs' className='link' style={{color:'white'}}><p className='dropdown-content'>Careers</p></Link> */}
            <p className='dropdown-content'>Brand Guidelines</p>
            <p className='dropdown-content'>Terms of Service</p>
            <p className='dropdown-content'>Privacy Policy</p>
          </div> : null}
          <p className='dropdown-head'>Follow Us On</p>
          <Row style={{margin:'0px',marginLeft:'20px',marginTop:'-5px'}}>
                 <Col md="2" xs={2}>
                 <a href="https://www.linkedin.com/company/obo-health" target="_blank" style={{color:'white',textDecoration:'none'}}><AiFillLinkedin className='linkedin-icon'/></a>
                 </Col>
                 <Col md="2" xs={2}>
                 <a href="https://www.facebook.com/obotechnology" target="_blank" style={{color:'white',textDecoration:'none'}}><AiFillFacebook className='fb-icon'/></a>

                   </Col>
                   <Col md="2" xs={2}>
                   <a href="https://twitter.com/obo_health" target="_blank" style={{color:'white',textDecoration:'none'}}><AiOutlineTwitter className='twitter-icon'/></a>
                   </Col>
                   <Col md="2" xs={2}>
                    <a href="https://www.instagram.com/obohealth" target="_blank" style={{color:'white',textDecoration:'none'}}><AiFillInstagram className='insta-icon'/></a>
                   </Col>
               </Row>
               <p className='dropdown-content'>(410)999-0303</p>
          <p className='dropdown-content'>1940,Fountain View Dr.#1250,Houston, Texas 77057,US</p>  
      </div>
     
      </div>
      
    )
  }
}

export default Footer