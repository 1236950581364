import React, { Component } from 'react';
import '../Styles/navbar.css'
import '../Styles/home.css'
import logo from '../Images/obologo.png'
import logowhite from '../Images/logowhite.PNG'
import {AiOutlineRight,AiFillCloseCircle,AiOutlineClose} from 'react-icons/ai'
import {BiChevronDown} from 'react-icons/bi'
import {GrClose} from 'react-icons/gr'
import {Row,Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Avatar from 'react-avatar'
import { SideNav } from 'react-simple-sidenav';
import {FaBars,FaWindowClose} from 'react-icons/fa'
import ScrollArea from 'react-scrollbar'
import { fallDown as Menu } from 'react-burger-menu'

class Navbar extends Component {
    constructor(props){
        super(props);
        this.state = {
            clicked: false,
            opendashboard:false,  
            products:false
        }
      
    }

    closedashboard = () =>{
        this.setState({opendashboard:false})
    }

    opendashboard = () => {
        this.setState({opendashboard:true})
    }
    
    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }
    render() {
        return (
            <div>
                <div className={this.props.color==="white" ? 'bars' : 'bars-top'} style={{backgroundColor:this.props.color,boxShadow:this.props.shadow}}>
 
                    
              
             
            <Link to='/'><img src={this.props.color==="#041b3d" ? logowhite : logo} className='mob-nav-logo'/></Link>
          <FaBars className='bar-icon' style={{color:this.props.textColor}} onClick={()=>this.setState({opendashboard:true})}/>
            <SideNav showNav={this.state.opendashboard}  openFromLeft="true" children={
                 <ScrollArea horizontal={false} vertical={true} style={{ position: 'relative', zIndex: '1000' }}>
  <div>
  <Link to='/'><img src={logo} className='mob-logo'/></Link>
    <GrClose className='close-icon'   onClick={()=>this.setState({opendashboard:false})}/>
    
    <p className='dropdown-head'>Products&nbsp;&nbsp;<BiChevronDown className='dropdown-icon' onClick={()=>this.setState({products:!this.state.products})}/></p>
    {this.state.products ? <div className='d-dropdown'>
                                   <a href="/products/denial-and-ars-platform">
                                   <Row style={{margin:'0px'}}>
                                           <Col xs="2">
                                               <Avatar round={true} src="https://m.media-amazon.com/images/I/61peIdz-C2L._SL1024_.jpg" size='50'/>
                                           </Col>
                                           <Col xs="10">
                                           <h5 className='d-head'>Denial and ARS Platform</h5>
                                           <p className='d-desc'>Say Good-Bye to calling the insurance to obtain the status of the claims</p>
                                           </Col>
                                       </Row>
                                   </a>
                                   <a href='/products/radiology' className='link'>
                                       <Row style={{margin:'0px'}}>
                                           <Col xs="2">
                                               <Avatar round={true} src="https://m.media-amazon.com/images/I/61peIdz-C2L._SL1024_.jpg"  size='50'/>
                                           </Col>
                                           <Col xs="10">
                                           <h5 className='d-head'>Radiology</h5>
                                           <p className='d-desc'>OBO's - Radiology Platform has been our initial successful product</p>
                                           </Col>
                                       </Row>
                                        
                                   </a>
                                   <a href='/products/ehr'>
                                   <Row style={{margin:'0px'}}>
                                  
                                           <Col xs="2">
                                               <Avatar round={true} src="https://m.media-amazon.com/images/I/61peIdz-C2L._SL1024_.jpg"  size='50'/>
                                           </Col>
                                           <Col xs="10">
                                           <h5 className='d-head'>EHR Product</h5>
                                           <p className='d-desc'>Complete cloud based application customized for all specialities</p>
                                           </Col>
                                       </Row>
                                   </a>
                                  
                                 
                                 
                                   <a href='/products/er-ed'>
                                   <Row style={{margin:'0px'}}>
                                           <Col xs="2">
                                               <Avatar round={true} src="https://m.media-amazon.com/images/I/61peIdz-C2L._SL1024_.jpg"  size='50'/>
                                           </Col>
                                           <Col xs="10">
                                           <h5 className='d-head'>E/R,ED</h5>
                                           <p className='d-desc'>OBO’s – ER | ED Platform is the latest achievement to our success</p>
                                           </Col>
                                       </Row>
                                   </a>
                                   <a>
                                   <Row style={{margin:'0px'}}>
                                           <Col xs="2">
                                               <Avatar round={true} src="https://m.media-amazon.com/images/I/61peIdz-C2L._SL1024_.jpg" size='50'/>
                                           </Col>
                                           <Col xs="10">
                                           <h5 className='d-head'>PMS Product</h5>
                                           <p className='d-desc'>PMS Product</p>
                                           </Col>
                                       </Row>
                                   </a>     
    </div> : null}
    <Link to='/services' className='link' style={{color:'black'}} ><p className='dropdown-head'>Services</p></Link>
           <Link to='/research-and-development' className='link' style={{color:'black'}}><p className='dropdown-head'>R&D</p></Link>
           <Link to='/about-us' className='link' style={{color:'black'}}><p className='dropdown-head'>Company</p></Link>
           <Link to='/contact-us' className='link'><p className='nav-getdemo'>Get Demo</p></Link>

    </div>  
           
                 </ScrollArea>
            } onHideNav={() => this.setState({opendashboard:false})}/>
            </div>
               
           
            <div className={this.props.color==="white" ? 'navbar-fixed-top' :'navbar-top'} style={{backgroundColor:this.props.color,boxShadow:this.props.shadow}}>
                
                <nav className='navbar'>
                   <Link to="/"> <img src={this.props.color==="#041b3d" ? logowhite : logo} className="navbar-logo"/></Link>
                    <ul className='nav-links'>
                        <div className='nav-dropdown'>
                        <li className='nav-products' style={{color:this.props.textColor}}>Products</li>       
                        <div className='nav-dropdown-content'>
                            <div className='nav-normal'>
                            <Row>
                                <Col md="6" sm={6}>
                               
                                
                                <a href="/products/denial-and-ars-platform">
                                <Row style={{margin:'0px'}}>
                                        <Col md="3" sm={6}>
                                            <Avatar round={true} src="https://m.media-amazon.com/images/I/61peIdz-C2L._SL1024_.jpg" className='nav-avatar' size='60'/>
                                        </Col>
                                        <Col md="9" className="nav-hover" xs={12} sm={6}>
                                        <h5 className='nav-head'>Denial and ARS Platform</h5>
                                        <p className='nav-desc'>Say Good-Bye to calling the insurance to obtain the status of the claims</p>
                                        </Col>
                                    </Row>
                                </a>
                                <a href='/products/radiology' className='link'>
                                    <Row style={{margin:'0px'}}>
                                        <Col md="3" sm={6}>
                                            <Avatar round={true} src="https://m.media-amazon.com/images/I/61peIdz-C2L._SL1024_.jpg" className='nav-avatar' size='60'/>
                                        </Col>
                                        <Col md="9" className="nav-hover" sm={6}>
                                        <h5 className='nav-head'>Radiology</h5>
                                        <p className='nav-desc'>OBO's - Radiology Platform has been our initial successful product</p>
                                        </Col>
                                    </Row>
                                     
                                </a>
                                <a href='/products/ehr'>
                                <Row style={{margin:'0px'}}>
                               
                                        <Col md="3" sm={6}>
                                            <Avatar round={true} src="https://m.media-amazon.com/images/I/61peIdz-C2L._SL1024_.jpg" className='nav-avatar' size='60'/>
                                        </Col>
                                        <Col md="9" className="nav-hover" sm={6}>
                                        <h5 className='nav-head'>EHR Product</h5>
                                        <p className='nav-desc'>Complete cloud based application customized for all specialities</p>
                                        </Col>
                                    </Row>
                                </a>
                               
                                </Col>
                                <Col md="6" sm={6}>
                                <a href='/products/er-ed'>
                                <Row style={{margin:'0px'}}>
                                        <Col md="3" sm={6}>
                                            <Avatar round={true} src="https://m.media-amazon.com/images/I/61peIdz-C2L._SL1024_.jpg" className='nav-avatar' size='60'/>
                                        </Col>
                                        <Col md="9" className="nav-hover" sm={6}>
                                        <h5 className='nav-head'>E/R,ED</h5>
                                        <p className='nav-desc'>OBO’s – ER | ED Platform is the latest achievement to our success</p>
                                        </Col>
                                    </Row>
                                </a>
                                <a>
                                <Row style={{margin:'0px'}}>
                                        <Col md="3" sm={4}>
                                            <Avatar round={true} src="https://m.media-amazon.com/images/I/61peIdz-C2L._SL1024_.jpg" className='nav-avatar' size='60'/>
                                        </Col>
                                        <Col md="9" className="nav-hover" sm={9}>
                                        <h5 className='nav-head'>PMS Product</h5>
                                        <p className='nav-desc'>PMS Product</p>
                                        </Col>
                                    </Row>
                                </a>
                                </Col>
                            </Row>
                      </div>
                      <div className='nav-res'>
                        <a href="/products/denial-and-ars-platform">Denial and ARS Platform</a>
                        <a href="/products/radiology">Radiology</a>
                        <a href="/products/ehr">EHR</a>
                        <a href="/products/er-ed">E/R,ED</a>
                        <a>PMS</a>
                      </div>
                        
                        </div>
                        </div>
                        <Link to="/services" className='link'><li className='nav-services' style={{color:this.props.textColor}}>Services</li></Link>
                        <Link to="/research-and-development" className='link'><li className='nav-rd' style={{color:this.props.textColor}}>R & D</li></Link>
                        <Link to='/about-us' className='link'><li className='nav-company' style={{color:this.props.textColor}}>Company</li></Link>
                        <Link to='/contact-us' className='link'><li className={this.props.color==="white" ? 'nav-getdemo' : 'getdemo-nav'}>Get Demo</li></Link>
                    </ul>
                </nav>
            </div>
            </div>
        )
    }
}
export default Navbar;

{/*  */}

