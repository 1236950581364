import React from 'react'
import {Routes,Route,BrowserRouter} from 'react-router-dom'
import Navbar from './Navbar'
import Home from './nav/Home'
import Footer from './Footer'
import Services from './nav/Services'
import RD from './nav/R&D'
import Company from './nav/Company'
import GetDemo from './nav/GetDemo'
import Radiology from './Products/Radiology'
import ER from './Products/ER'
import Denial from './Products/Denial'
import Positions from './Products/Positions'
import Applyjobs from './Products/Applyjobs'
import StartTrial from './Products/StartTrial'
import EHR from './Products/EHR'
import Chatbot from './Chatbot'
import '../Styles/Footer.css'
import Success from './Features/Success'
import KeyTrends from './Features/KeyTrends'
import RCM from './Features/RCM'
import UserExp from './Blogs/UserExp'
import AI from './Blogs/AI'
import Trends from './Blogs/Trends'
function App() {
  return (
    <div>
    <BrowserRouter>
    {/* <Navbar/> */}
    <Routes>
      
        <Route exact path='/' element={< Home />}></Route>
        <Route exact path='/services' element={< Services />}></Route>
        <Route exact path='/research-and-development' element={< RD />}></Route>
        <Route exact path='/about-us' element={<Company/>}></Route>
        <Route exact path="/contact-us" element={<GetDemo/>}></Route>
        <Route exact path="/products/radiology" element={<Radiology/>}></Route>
        <Route exact path="/products/er-ed" element={<ER/>}></Route>
        <Route exact path="/products/denial-and-ars-platform" element={<Denial/>}></Route>
        <Route exact path="/find-jobs" element={<Positions/>}></Route>
        <Route exact path='/apply-jobs' element={<Applyjobs/>}></Route>
        <Route exact path='/start-trial' element={<StartTrial/>}></Route>
        <Route exact path='/products/ehr' element={<EHR/>}></Route>
        <Route exact path='/features/denial-management' element={<Success/>}/>
        <Route exact path='/features/medical-billing' element={<KeyTrends/>}/>
        <Route exact path='/features/RCM' element={<RCM/>}/>
        <Route exact path='/blogs/prioritizing-user-experience' element={<UserExp/>}/>
        <Route exact path='/blogs/potential-of-ai' element={<AI/>}/>
        <Route exact path='/blogs/healthcare-technology-trends-and-innovations' element={<Trends/>}/>
    </Routes>
    <br/><br/><br/><br/>
    <div>
    <Footer/>
    <Chatbot/>
    </div>
   
    </BrowserRouter>
    </div>
  )
}

export default App