import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import Navbar from '../Navbar'
export class KeyTrends extends Component {
  componentDidMount(){
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
           <Navbar color="#041b3d" textColor="white"/>
          <div className='company-top'>
           <p className='company-tophead' align='center'>Key Trends in Medical Billing Practice</p>
          </div><br/>
          <Container>
           
              <p className='f-content'>Innovation is a necessity to any industry and RCM Healthcare is no different. Some of the new trends adopted by Healthcare providers from 2018 are Incorporation of AI into Medical billing, Shift to value-based healthcare, EHR Alignment, etc. Since then, the thirst to bring new solutions to the existing challenges in RCM Healthcare have increased manifold. </p>
            <p className='f-content'>Emerging trends such as blockchain outsourcing of billing and computer-assisted coding (CAC) are being increasingly adopted by RCM players and providers. </p>
            <p className='f-content'>It is critical for all the stakeholders involved in RCM Healthcare industry to adopt to these changes as it will improve their business. </p>
            <p className='f-content'>Let’s take a look at what are the key trends in medical billing practice which are essential – </p>
            <br/><p className='f-head'>Introduction of Blockchain in Medical billing</p><br/>
            <p className='f-content'>Medical billing process comes with many challenges such as unintentional billings, billing code errors, incorrect filings, and duplication of processes.</p>
            <p className='f-content'>Blockchain helps the providers in reducing the number of errors by showing proof after the transactions are completed. Ensuring data integrity, this technology protects the medical organizations’ data including electronic health records (EHR), patient health information (PHI), monitoring systems data, and claims data.</p>
            <p className='f-content'>Through blockchain technology, all the billing records are stored in a way that they cannot be modified until it reaches the recipient. All data is readily available and saved in a fixed location.</p>
           <br/><p className='f-head'>Automated Billing</p><br/>
            <p className='f-content'>Through automated billing, key process such as claims submission, managing insurance, remittance, etc are handled by cloud-based software. This practice reduces the risk of error in claims and leads to minimized payment delays and denials.</p>
          <br/><p className='f-head'>Virtual Medical Care</p><br/>
            <p className='f-content'>Virtual Medical Care helps you to connect with your patients without calling them to your clinic. It bridges the gap between physician and patient and allows you to move from fee for service model to value-based care.</p>
            <br/><p className='f-head'>Medical Billing Outsourcing</p><br/>

            <p className='f-content'>Medical Billing outsourcing has emerged as an important requirement for many healthcare providers. As RCM involves numerous complex steps including patient scheduling, enrolment, insurance verification, submission of claims, follow-ups and payment collection; outsourcing the medical billing service to an expert RCM vendor will streamline the workflow and give personal attention to patients. </p>
            <p className='f-content'>To achieve better control over your business processes, outsourcing is key. It saves time, money and gives you increased revenues. </p>
            <br/><p className='f-head'>OBO Health – Your ideal partner for Medical Billing services</p><br/>
            <p className='f-content'>OBO is an organization driven by passionate young leaders having end-end RCM experience for over two decades. Managing large-sized hospitals and physician groups like IPA’s, ACOs, Acute Care Hospital, and Large Physician Groups revenue cycle is our key competence.</p>
            <p className='f-content'>The birth of OBO was an outcome of process hard-ships the hospitals, physician groups, and independent physician groups have to experience every day to get their reimbursement. We felt our clients deserve a much better experience to treat the patients at ease while iMagnum manages their End-End Revenue Cycle.</p>
            <p className='f-content'>Our vision is to build the process on Technology solutions that provides enhanced experience for our clients in terms of speed of delivery, quality, and most importantly, transparency and accountability with complete ownership of End – End Revenue Cycle.</p>
            <br/><p className='f-head'>Key differentiators of OBO health:</p><br/>
            <p className='f-content'>
                <ol>
                    <li>Service Delivery at 5x speed with the industry accepted Quality Standards</li>
                    <li>The most cost effective player in the RCM industry</li>
                </ol>
            </p>
            <br/><p className='f-head'>OBO - Medical Billing Services</p><br/>
            <b className='f-content'>Pre-billing</b>
            <p className='f-content'>
                <ul>
                    <li>Enrollment and Credentialing services</li>
                    <li>Patient Registration</li>
                    <li>Eligibility Verification and Prior Authorization</li>
                    <li>Medical Coding</li>
                    <li>Charge Entry Services</li>
                    <li>Charge Audit Services</li>
                    <li>Edits</li>
                </ul>
            </p>
            <b className='f-content'>Post-billing</b>
            <p className='f-content'>
                <ul>
                    <li>Payment posting – ERA and Manual</li>
                    <li>Credit Balance and Refund</li>
                    <li>Denial Posting</li>
                    <li>Denial Management</li>
                    <li>Accounts Receivable</li>
                    <li>Old A/R recovery</li>
                    <li>Patients statement</li>
                    <li>Appeal process</li>


                </ul>
            </p>
          
          </Container>
      </div>
    )
  }
}

export default KeyTrends