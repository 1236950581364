import React, { Component } from "react";
import "../../Styles/company.css";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import whatwe from "../../Images/whatwedo.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Avatar from "react-avatar";
import { GrLocation } from "react-icons/gr";
import { Rating } from "react-simple-star-rating";
import Navbar from "../Navbar";
import why from "../../Images/why.png";
import vision from "../../Images/vm.jpg";
import ReactOwlCarousel from "react-owl-carousel";
import pic1 from "../../Images/pic1.jpeg";
import pic2 from "../../Images/pic2.jpeg";
import prakash from "../../Images/prakash.jpg";
import kasi from "../../Images/kasi.jpeg";
import ajith from "../../Images/ajith.jpg";
import sanjith from "../../Images/sanjith.jpeg";
export class Company extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Navbar color="#041b3d" textColor="white" />
        <div className="company-top">
          <p className="company-tophead" align="center">
            About OBO
          </p>
        </div>
        <Container>
          <Row style={{ margin: "0px" }}>
            <Col md="8" sm={12} xs={12}>
              <h4 className="h-heading">What do we do @ OBO Health Inc.?</h4>
              <p className="h-content">
                Automation in Healthcare is still in its early nascent stage.
                There is an enormous opportunity to make the end-to-end process
                highly automation dependent and shed off the lean, mundane
                process.
                <br />
                <br />
                OBO Health is a Technology Company with its core focus on
                Developing Healthcare Technology Products to minimize the
                Revenue Cycle Management process complexity in the US Healthcare
                Industry.{" "}
              </p>
            </Col>
            {/* <Col md="1"></Col> */}
            <Col md="3" sm={12} xs={12}>
              <img src={whatwe} className="wobo-img"></img>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row style={{ margin: "0px" }}>
            <Col>
              <img src={why} className="team-pic"></img>
            </Col>
            <Col md="1"></Col>
            <Col md="7" xs={12} sm={12}>
              <br />
              <br />
              <h4 className="h-heading">Why OBO Health?</h4>
              <p className="h-content">
                We are True Believers in the Quote “Every Challenge is an
                Opportunity,” and products developed with this belief help us
                establish a strong connection with our customers.
                <br />
                <br />
                <p className="h-content company-extra">
                  At OBO Health, we are believers in the theory of building a
                  resilient, admirable, and accountable technology that can
                  spread the wings to a larger spectrum of Healthcare Industry
                  with higher ROI. Our experience in this industry for several
                  decades and our unmatched wisdom enabled us to dream big in
                  developing such a platform.
                </p>
              </p>
            </Col>
            {/* <img src={why} className='team-pic-res'></img> */}
          </Row>
        </Container>
        <Container>
          <Row style={{ margin: "0px" }}>
            <Col md="6" xs={12} sm={12}>
              <h4 className="h-heading">Vision & Mission</h4>
              <br />
              <p className="h-content">
                <b>VISION:</b>&nbsp;To Be “The Most Entrusted Organization, Who
                Can Truly Provide Innovative Technology Solutions to the
                Healthcare Provider community to improve the Patient care.”
              </p>
              <p className="h-content">
                <b>MISSION:</b>&nbsp;We Persevere to Architect Technology
                Solutions That Is Affordable to Everyone Regardless of the Size
                of Our Customer, Because Success Doesn't Have Relativity
              </p>
            </Col>
            {/* <Col md="1"></Col> */}
            <Col style={{ paddingTop: "2%" }} md="3" sm={12} xs={12}>
              <img src={vision} className="vm-img"></img>
            </Col>
          </Row>
          <br />
          <br />
        </Container>
        {/* <div style={{ backgroundColor: "#041b3d", color: "#fff" }}>
          <br />
          <h4 align="center" className="h-heading">
            Our Team
          </h4>
          <br />
          <br />
          <Container>
            <Row style={{ margin: "0px" }}>
              <Col md="4" xs={12} sm={12}>
                <img src={prakash} className="team-img" />
              </Col>
              <Col md="1" />
              <Col md="7" xs={12} sm={12}>
                <h4 className="team-head">Prakash</h4>
                <h6 className="team-subhead">Co Founder</h6>
                <p className="h-content team-desc">
                  Prakash is a seasoned, dynamic RCM professional with over two
                  decades of experience. He is a leader with diversified
                  expertise in Operations Management, Client Relations, and
                  Sales. Prakash has the unique leadership style of building
                  strong networks in this industry and has a proven record of
                  building an organization from scratch through his intuitive
                  business acumen for onboarding clients and creating an
                  environment that makes the clients feel comfortable to grow
                  the business.
                </p>
              </Col>
            </Row>
          </Container>
          <hr className="hr-team" />
          <br />
          <Container>
            <Row style={{ margin: "0px" }}>
              <Col md="4" xs={12} sm={12}>
                <img src={kasi} className="team-img" />
              </Col>
              <Col md="1"></Col>
              <Col md="7" xs={12} sm={12}>
                <h4 className="team-head">Kasiraman</h4>
                <h6 className="team-subhead">Co Founder</h6>
                <p className="h-content team-desc">
                  Kasiraman is a futuristic leader passionate about leveraging
                  technology-enabled solutions to make complex business work
                  effortlessly. His multi-faceted knowledge will help OBO ideate
                  solutions for the ever-evolving challenges in this exhaustive
                  RCM world. Kasi brings 19+ years of solid Client & People
                  Management and Blackbelt+ Certified AI & ML professional. He
                  is a Novel and adaptive thinker who conceives solutions
                  through his expertise that will cater to large and small
                  communities of Physicians and Hospitals that will thrive in
                  increasing healthcare costs and make the medical fraternity
                  service at ease.
                </p>
              </Col>
            </Row>
          </Container>
          <hr className="hr-team" />
          <br />
          <Container>
            <Row style={{ margin: "0px" }}>
              <Col md="4" xs={12} sm={12}>
                <img src={ajith} className="team-img" />
              </Col>
              <Col md="1" />
              <Col md="7" xs={12} sm={12}>
                <h4 className="team-head">Ajith Kumar</h4>
                <h6 className="team-subhead">Co Founder</h6>
                <p className="h-content team-desc">
                  Ajith Kumar is a Tech entrepreneur and Product Developer who
                  built and scaled multiple AI-infused software products from
                  idea to launch. Also made the first git push for obo products.
                  Ajith completed his Master of Science from the College of
                  Engineering, Guindy (CEG). Published Multiple research papers
                  related to the field of Artificial intelligence and computer
                  science in international journals. Ajith has a proven record
                  of building a highly productive team and organization from
                  scratch. At OBO Ajith is responsible for the technology
                  roadmap of the company.
                </p>
              </Col>
            </Row>
          </Container>
          <hr className="hr-team" />
          <Container>
            <Row style={{ margin: "0px" }}>
              <Col md="4" xs={12} sm={12}>
                <img src={sanjith} className="team-img" />
              </Col>
              <Col md="1" />
              <Col md="7" xs={12} sm={12}>
                <h4 className="team-head">Sanjith</h4>
                <h6 className="team-subhead">Co Founder</h6>
                <p className="h-content team-desc">
                  Sanjith is responsible for the strategy and execution of all
                  product-related activities for OBO. He has more than a decade
                  experience in all verticals of US healthcare which helps OBO
                  in deep understanding of technology & product development
                  combined with exceptional skills. Sanjith is an enthusiastic
                  person who goes well with his ability to connect and lead the
                  team of professionals. At OBO, he will be in charge of
                  orchestrating product development initiatives, managing
                  stakeholders, devising product strategy for growth &
                  spearheading high-performing teams as part of developing
                  products and services.
                </p>
              </Col>
              <Col md="2"></Col>
            </Row>
          </Container>
          <br />
          <br />
        </div> */}
        {/* <h4 align="center" className='h-heading'>Here is how our employees feels about us</h4><br/> */}
        {/* <Container>
          <Row style={{margin:'0px'}}>
            <Col md="1"/>
          <Col md="5" sm={12} xs={12}>
          <br/><br/><br/>
            <p className='c-content'>Happy Customers!</p>
            <p className='c-heading'>We give our best to make<br/>you happy!</p>
            <p className='c-content'>Hear what our customers says about us!</p>
          </Col>  
          <Col md="5" sm={12} xs={12}>
          <Carousel
         showArrows={true}
         infiniteLoop={true}
         autoPlay={true}
         showThumbs={false}
         showStatus={false}
        >
          <div className='h-carousel'>
           <Avatar src="https://image.shutterstock.com/image-photo/portrait-young-man-crossed-hands-260nw-582575041.jpg" className='c-img' round={true} size={80}/> 
           
           <h3 className='c-heading'>Krishnan</h3>
           <Rating initialValue="4" readonly={true} size={30} className='rating'/><br/>
           <p >"If you have it in you, this place will embrace the career path you seek."</p>
          </div>
          <div className='h-carousel'>
           <Avatar className='c-img' src="https://image.shutterstock.com/image-photo/portrait-young-man-crossed-hands-260nw-582575041.jpg" round={true} size={80}/> 
           
           <h3 className='c-heading'>Naresh</h3>
           <Rating initialValue="3" readonly={true} size={30}  className='rating'/><br/>
           <p >"One of the few companies which acknowledges and celebrates people and their work." </p>
          </div>
          </Carousel><br/>
          </Col>  
          <Col md="1"/>
          </Row>
          </Container>*/}
        <br />
        <br /> <br />
        <Container>
          <Row style={{ margin: "0px" }}>
            <Col md="6" xs={12} sm={12}>
              <div className="c-card-row">
                <ReactOwlCarousel
                  items={1}
                  className="owl-theme"
                  loop={true}
                  dots={false}
                  autoplay={true}
                  autoplayTimeout={3000}
                >
                  <div className="rd-carousel">
                    <img src={pic1} style={{ width: "100%" }} />
                  </div>
                  <div className="rd-carousel">
                    <img src={pic2} style={{ width: "100%" }} />
                  </div>
                </ReactOwlCarousel>
                <br />
                <br />
                <Row style={{ margin: "0px" }}>
                  <Col md="6" xs={6} sm={6}>
                    <div className="location-card">
                      <b>India</b>
                      <br />
                      <br />
                      <p className="address">
                        25, 1st Floor, 1st Main Rd, United India Colony,
                        Kodambakkam, Chennai
                      </p>
                    </div>
                  </Col>
                  <Col md="6" xs={6} sm={6}>
                    <div className="location-card">
                      <b>USA</b>
                      <br />
                      <br />
                      <p className="address">
                        1940, Fountain View Dr.#1250, Houston, <br />
                        Texas 77057
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md="6" xs={12} sm={12}>
              <div className="company-card">
                <h3 style={{ fontWeight: "bold" }}>Get in Touch</h3>
                <br />
                <p className="h-content">
                  Have an inquiry or some feedback for us?Fill out the form
                  below to contact our team.
                </p>
                <Row style={{ margin: "0px" }}>
                  <Col md="6" xs={12} sm={12}>
                    <label className="c-label">First Name</label>
                    <input
                      type="text"
                      placeholder="Your Name"
                      className="gd-input"
                    ></input>
                  </Col>
                  <Col md="6" xs={12} sm={12}>
                    <label className="c-label">Last Name</label>
                    <input
                      type="text"
                      placeholder="Your Name"
                      className="gd-input"
                    ></input>
                  </Col>
                </Row>
                <Row style={{ margin: "0px" }}>
                  <Col md="12" xs={12} sm={12}>
                    <label className="c-label">Email address</label>
                    <input
                      type="text"
                      placeholder="Your Email Address"
                      className="gd-input"
                    ></input>
                  </Col>
                </Row>
                <Row style={{ margin: "0px" }}>
                  <Col md="12" xs={12} sm={12}>
                    <label className="c-label">Message</label>
                    <textarea
                      placeholder="Your Message"
                      rows={4}
                      className="gd-textarea"
                    />
                  </Col>
                </Row>
                <button className="getintouch-btn">
                  Get&nbsp;in&nbsp;touch
                </button>
              </div>
            </Col>
          </Row>
        </Container>
        {/* <h4 className='h-heading' align="center">Wanna join us?</h4> */}
        {/* <Link to="/find-jobs" className="link"> <p className='getdemo-center' align="center">See open positions</p></Link> */}
      </div>
    );
  }
}
export default Company;
