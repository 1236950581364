import React, { Component } from 'react'
import '../../Styles/products.css'
import {Row,Col, Container} from 'react-bootstrap'
import {MdUpcoming} from 'react-icons/md'
import {BsFillArrowRightCircleFill,BsFillArrowUpRightSquareFill,BsFillCheckCircleFill,BsGraphUp} from 'react-icons/bs'
import home from '../../Images/home.svg'
import side1 from '../../Images/side1.svg'
import outcome from '../../Images/outcome.PNG'
import {Carousel} from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Link} from 'react-router-dom'
import video2 from '../../Images/video2.mp4'
import {BiVideo} from 'react-icons/bi'
import HoverVideoPlayer from 'react-hover-video-player'
import Navbar from '../Navbar';
import ReactOwlCarousel from 'react-owl-carousel'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
export class ER extends Component {
  componentDidMount(){
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
         <Navbar color="white" textColor="black" shadow="0 2px 5px rgba(0,0,0,.2)"/>
          <h4 className='p-heading'>E/R,ED</h4>
          <p className='p-content'>OBO’s – ER | ED Platform is the latest achievement to our success</p>
          <Row style={{margin:'0px'}}>
              <Col md="4"></Col>
              <Col md="2" xs={6} sm={6}>
              <Link to='/contact-us' className='link'><p align="center" className='p-getdemo'>Get Demo</p></Link>
              </Col>
              <Col md="2" xs={6} sm={6}>
              <Link to='/start-trial' className='link'><p align="center" className='p-starttrial'>Start Trial&nbsp;&nbsp;&nbsp;<BsFillArrowRightCircleFill className='starttrial-icon'/></p></Link>
              </Col>
              <Col md="4"></Col>
          </Row><br></br>
          <Row style={{margin:'0px'}}>
              <Col md="2">
                  <img src={side1} className='p-img'/>
              </Col>
              <Col md="8" xs={12} sm={12}>
              <Link to='/contact-us' className='link'>
              <HoverVideoPlayer
                // videoSrc={video2}
                className="product-video"
                pausedOverlay={
                   <div className="product-thumbnail">
                    <p className="watch-product" align="center">Watch Our Product&nbsp;&nbsp;<BiVideo className="video-icon"/></p>
                    </div>  
                }
                loadingOverlay={
                    <div className="c-loading-spinner-overlay">Loading...</div>
                }
                restartOnPaused
                />
                </Link>
              </Col>
              <Col md="2">
                  <img src={home} className='p-img'/>
              </Col>
          </Row>
         <br/><br/>
           <h4 className='p-keyf'>Key Features</h4>
           <br/><br/>
           <Container>
          <ReactOwlCarousel
        items={1} 
        className="owl-theme" 
        loop={true}
        nav 
        dots={false}
        autoplay={true}
        autoplayTimeout={3000}
        >
          <div className='rd-carousel'>
            <Row style={{margin:'0px'}}>
            
              {/* <Col md="6" xs={12} sm={12}>
              <img src="https://m.media-amazon.com/images/I/61peIdz-C2L._SL1024_.jpg" className='keyf-img'/>
              </Col> */}
              <Col md="12" xs={12} sm={12}>
                <p className='p-keyf-heading'>Key components for Coding ED/ER</p>
                <p className='p-keyf-content'>
                1.Our NLP Engine is equipped to understand History, Examination & Medical Decision-Making components<br/>2.Ensures it is appropriately documented for selecting the level of service
                </p>
              </Col>
             
            </Row>
          </div>
          <div className='rd-carousel'>
          <Row style={{margin:'0px'}}>
        
              {/* <Col md="6" sm={12} xs={12}>
              <img src="https://m.media-amazon.com/images/I/61peIdz-C2L._SL1024_.jpg" className='keyf-img'/>
              </Col> */}
              <Col md="12" sm={12} xs={12}>
                <p className='p-keyf-heading'>Addressing the Pressing Problems</p>
                <p className='p-keyf-content'>
                1.Signs-Symptoms are ignored at times leading to problems with the level<br/>2.Modality severity is another aspect our engine identifies effectively.

                </p>
              </Col>
             
            </Row>
          </div>
          <div className='rd-carousel'>
          <Row style={{margin:'0px'}}>

              {/* <Col md="6" xs={12} sm={12}>
              <img src="https://m.media-amazon.com/images/I/61peIdz-C2L._SL1024_.jpg" className='keyf-img'/>
              </Col> */}
              <Col md="12" sm={12} xs={12}>
                <p className='p-keyf-heading'>In-Built with CDR guidelines</p>
                <p className='p-keyf-content'>
                1.Our NLP engines makes like easier for coding ED/EM charts with the integrated CDR guidelines to call-out issues in the coding, prediction<br/>2.MDM (HEM) guidelines measured

                </p>
              </Col>
            </Row>
          </div>
          </ReactOwlCarousel>
          </Container>
          <br/><br/>
          <h4 className='p-keyf'>Features</h4>
          <br/>
          <Container>
          <Row style={{margin:'0px'}}>
          <Col md="1"></Col>
              <Col md="5" xs={12} sm={6}>   
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Review Patient Age, In-Time & Out-Time</p>
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Review Patient Age and Chief Complaint</p>
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Review HPI, PFSH, ROS, PE</p>
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;MDM</p>
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Medication Details & Nurse Notes</p>
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Other reports from other specialties like Radiology reports</p>
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Examining CDR Guidelines to pick up the appropriate guideline </p>
              </Col>
              <Col md="5" xs={12} sm={6}>
              
              <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;It can pick up the insurance information too, to categorize the Timely Filing days available (this is done through a master file that has most of the payers and their TFL limits)</p>
              <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;ICD Sequencing based on the Procedure and leveling</p>
              <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Applying client-specific rules (if provided by us) the system can categorize the deficient charts as well</p>
              <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;And much more logic in-built for an improved accuracy/prediction</p>


              </Col>
              <Col md="1"></Col>
              
          </Row>
          </Container>
          <br/>
          <br/>
          <h4 className='p-keyf'>Outcomes</h4><br/>
          <Container>
          <Row style={{margin:'0px'}}>
          
          <Col md="6" xs={12} sm={12}>
            <p className="p-outcome">Improved collections and enhanced Daily Cash Flow</p>
            <p className="p-outcome">Reduction in Coding Denials</p>
            <p className="p-outcome">Productivity Improvement by 30% to 55%</p>
            <p className="p-outcome">Quality can be set aside on an Auto-Pilot mode</p>
            <p className="p-outcome">Critical–Key Performance Indicators to evaluate the Health of the Process</p>
            <p className="p-outcome">Transparency and Real-Time Performance status</p>
          </Col>
          <Col md="6" xs={12} sm={12}>
        <img src={outcome} className='outcome-img'/>
          </Col>
          </Row>
          </Container>
      </div>
    )
  }
}

export default ER