import React, { Component } from 'react'
import '../../Styles/products.css'
import {Row,Col, Container} from 'react-bootstrap'
import {MdUpcoming} from 'react-icons/md'
import {BsFillArrowRightCircleFill,BsGraphUp,BsFillCheckCircleFill} from 'react-icons/bs'
import {GiProgression} from 'react-icons/gi'
import {MdOutlineAutoGraph} from 'react-icons/md'
import home from '../../Images/home.svg'
import side1 from '../../Images/side1.svg'
import outcome from '../../Images/outcome.PNG'
import {Carousel} from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Link} from 'react-router-dom'
import Navbar from '../Navbar'
import video2 from '../../Images/denial.mp4'
import {BiVideo} from 'react-icons/bi'
import HoverVideoPlayer from 'react-hover-video-player'
import denial from '../../Images/denial.PNG'
import ReactOwlCarousel from 'react-owl-carousel'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
export class Denial extends Component {
  componentDidMount(){
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
         <Navbar color="white" textColor="black" shadow="0 2px 5px rgba(0,0,0,.2)"/>
          <h4 className='p-heading'>Denial and ARS Platform</h4>
          <Container>
          <Row style={{margin:'0px'}}>
          <Col md="1"></Col>
              <Col md="10" xs={12} sm={12}>
              <p className="p-denial-content" align="center">Say Good-Bye to calling the insurance to obtain the status of the claims</p>
              </Col>
              <Col md="1"></Col>
          </Row>
          </Container>
          <Row style={{margin:'0px'}}>
              <Col md="4"></Col>
              <Col md="2" xs={6} sm={6}>
              <Link to='/contact-us' className='link'><p align="center" className='p-getdemo'>Get Demo</p></Link>
              </Col>
              <Col md="2" xs={6} sm={6}>
                 <Link to='/start-trial' className='link'> <p align="center" className='p-starttrial'>Start Trial&nbsp;&nbsp;&nbsp;<BsFillArrowRightCircleFill className='starttrial-icon'/></p></Link>
              </Col>
              <Col md="4"></Col>
          </Row><br></br>
          <Row style={{margin:'0px'}}>
              <Col md="2">
                  <img src={side1} className='p-img'/>
              </Col>
              <Col md="8" xs={12} sm={12}>
                <Link to='/contact-us' className='link'>
              <HoverVideoPlayer
                // videoSrc={video2}
                className="product-video"
                pausedOverlay={
                   <div className="product-thumbnail">
                    <p className="watch-product" align="center">Watch Our Product&nbsp;&nbsp;<BiVideo className="video-icon"/></p>
                    </div>  
                }
                loadingOverlay={
                    <div className="c-loading-spinner-overlay">Loading...</div>
                }
                restartOnPaused
                />
                </Link>
              </Col>

              <Col md="2">
                  <img src={home} className='p-img'/>
              </Col>
          </Row>
         <br/><br/>
           <h4 className='p-keyf'>Key Features</h4>
           <br/><br/>
           <Container>
          <ReactOwlCarousel
          items={1} 
          className="owl-theme" 
          loop={true}
          nav 
          dots={false}
          autoplay={true}
          autoplayTimeout={3000}
        >
          <div className='rd-carousel'>
            <Row style={{margin:'0px'}}>
             
              {/* <Col md="6" xs={12} sm={12}>
              <img src="" className='keyf-img'/>
              </Col> */}
              <Col md="12" xs={12} sm={12}>
                <p className='p-keyf-heading'>Predictive Analysis</p>
                <p className='p-keyf-content'>
                1.Extensive Data Mining, Predictive Models and Machine Learning algorithms provides the best experience of getting awareness of the potential revenue and possible denials.
<br/>2.Capable of identifying outliers, tracking their probability of occurrence, and refining the algorithm by itself are some of the great features of our application

                </p>
              </Col>
            
            </Row>
          </div>
          <div className='rd-carousel'>
          <Row style={{margin:'0px'}}>
        
              {/* <Col md="6" sm={12} xs={12}>
              <img src={denial} className='keyf-img'/>
              </Col> */}
              <Col md="12" sm={12} xs={12}>
                <p className='p-keyf-heading'>5x speed of claims resolutions</p>
                <p className='p-keyf-content'>
            1.Our Vision is to build products that can scale to significant numbers<br/>
2.Our product has been developed to ease of the challenges in scaling from a resource standpoint <br/>
3.What if we say “Claims for patients serviced at 10am EST been sent to insurance and accepted by them at 12:00 noon EST”
<br/>4.Does anything else is required to highlight how the cash flow increases for our clients?



                </p>
              </Col>
            
            </Row>
          </div>
          <div className='rd-carousel'>
          <Row style={{margin:'0px'}}>
             
              {/* <Col md="6" xs={12} sm={12}>
              <img src={denial} className='keyf-img'/>
              </Col> */}
              <Col md="12" xs={12} sm={12}>
                <p className='p-keyf-heading'>Compare with your Peers & Industry Benchmarks</p>
                <p className='p-keyf-content'>
1.Our ALL-IN-ONE application can provide insights of your performance against similar industry peers<br/>
2.We have 53 Industry Benchmarks against which you can compare your performance.
                </p>
              </Col>
           
            </Row>
            
          </div>
          </ReactOwlCarousel>
          </Container>
          <br/>
          <h4 className='p-keyf'>Features</h4>
          <br/>
          <Container>
          <Row style={{margin:'0px'}}>
            <Col md="1"></Col>
              <Col md="5" xs={12} sm={6}>   
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Other reports from other specialities like Radiology reports</p>
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Examining CDR guidelines to pick up the appropriate guideline</p>
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;It can pick up the insurance information too,to categorize the Timely Filling days available(this is done through a master file that has most of the payers and their TFL limits)</p>
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;And much more logic in-built for an improved accuracy/prediction</p>
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Applying client-specific rules(if provided by us) the system can categorize the deficient charts as well</p>
              </Col>
              <Col md="5" xs={12} sm={6}>
              <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Review Patient Age,In-time and out-time</p>
              <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Review Patient Age,In-time and out-time</p>
              <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Review Patient Age and Chief Complaint</p>
              <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Review HPI,PFSH,ROS,PE</p>
              <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;MDM</p>
              <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;ICD Sequencing based on the procedure and leveling</p>
              <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Medication Details & Nurse Notes</p>

              </Col>
              <Col md="1"></Col>
          </Row>
          </Container>
          <br/>
          <br/>
          <h4 className="h-heading" align='center'>What distinguish between others</h4>
          <Container>
          <Row style={{margin:'0px'}}>
         
          <Col md="4" xs={12} sm={12}>
            <div className='card-dist'>
              <BsGraphUp className='dist-icon'/>
                <p className='dist-head'>ASSURED CASH FLOW IMPROVEMENT</p>
                <p className='dist-content'>Predict your cash flow and see the graph lines moving up as you work through the OBO Platform</p>
            </div>
          </Col>
          <Col md="4" xs={12} sm={12}>
          <div className='card-dist'>
              <GiProgression className='dist-icon'/>
                <p className='dist-head'>INSTANT DENIAL ADDRESSAL SYSTEM</p>
                <p className='dist-content'>Do you know there are 53 Industry metrics to compare your client's project performance? You name it and we have it tracked through our platform</p>
            </div>
          </Col>
          <Col md="4" xs={12} sm={12}>
          <div className='card-dist'>
              <MdOutlineAutoGraph className='dist-icon'/>
                <p className='dist-head'>INDUSTRY METRICS </p>
                <p className='dist-content'>Do you know there are 53 Industry metrics to compare your client's project performance? You name it and we have it tracked through our platform</p>
            </div>
          </Col>
         
          </Row>
          </Container>
          <h4 className='p-keyf'>Outcomes</h4><br/>
          <Container>
          <Row style={{margin:'0px'}}>
         
          <Col md="6">
            <p className="p-outcome">Improved collections and enhanced Daily Cash Flow</p>
            <p className="p-outcome">Productivity Improvement by 30% to 55%</p>
            <p className="p-outcome">Quality can be set aside on an Auto-Pilot mode</p>
            <p className="p-outcome">Critical–Key Performance Indicators to evaluate the Health of the Process</p>
            <p className="p-outcome">Transparency and Real-Time Performance status</p>
            <p className="p-outcome">All claims diligently followed-up every 30 days  </p>
            <p className="p-outcome">Prevention of Denials</p>
            <p className="p-outcome">Improved Resolution rates</p>
            <p className="p-outcome">Lesser than Industry Benchmark for claims greater than 90 days</p>
          </Col>
          <Col md="6">
        <img src={outcome} className='outcome-img'/>
          </Col>
         
          </Row>
          </Container>
      </div>
    )
  }
}

export default Denial