import React, { Component } from 'react'
import '../../Styles/products.css'
import {Row,Col, Container} from 'react-bootstrap'
import {MdUpcoming} from 'react-icons/md'
import {BsFillArrowRightCircleFill,BsFillArrowUpRightSquareFill,BsFillCheckCircleFill} from 'react-icons/bs'
import home from '../../Images/home.svg'
import side1 from '../../Images/side1.svg'
import outcome from '../../Images/outcome.PNG'
import video2 from '../../Images/video2.mp4'
import {BiVideo} from 'react-icons/bi'
import HoverVideoPlayer from 'react-hover-video-player'
import {Carousel} from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Link} from 'react-router-dom'
import Navbar from '../Navbar'
import ehr from '../../Images/ehr.jpeg'
import ReactOwlCarousel from 'react-owl-carousel'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
export class EHR extends Component {
  componentDidMount(){
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
           <Navbar color="white" textColor="black" shadow="0 2px 5px rgba(0,0,0,.2)"/>
          <h4 className='p-heading'>EHR</h4>
          <p className='p-content'>Complete cloud based application customized for all specialities</p>
          <Row style={{margin:'0px'}}>
              <Col md="4"></Col>
              <Col md="2" xs={6} sm={6}>
              <Link to='/contact-us' className='link'><p align="center" className='p-getdemo'>Get Demo</p></Link>
              </Col>
              <Col md="2" xs={6} sm={6}>
              <Link to='/start-trial' className='link'> <p align="center" className='p-starttrial'>Start Trial&nbsp;&nbsp;&nbsp;<BsFillArrowRightCircleFill className='starttrial-icon'/></p></Link>
              </Col>
              <Col md="4"></Col>
          </Row><br></br>

          <Row style={{margin:'0px'}}>
              <Col md="2">
                  <img src={side1} className='p-img'/>
              </Col>
              <Col md="8" xs={12} sm={12}>
                <Link to='/contact-us' className='link'>
              <HoverVideoPlayer
                // videoSrc={video2}
                className="product-video"
                pausedOverlay={
                   <div className="product-thumbnail">
                    <p className="watch-product" align="center">Watch Our Product&nbsp;&nbsp;<BiVideo className="video-icon"/></p>
                    </div>  
                }
                loadingOverlay={
                    <div className="c-loading-spinner-overlay">Loading...</div>
                }
                restartOnPaused
                />
                </Link>
              </Col>
              <Col md="2">
                  <img src={home} className='p-img'/>
              </Col>
          </Row>
         <br/><br/>
           <h4 className='p-keyf'>Key Features</h4>
           <br/><br/>
           <Container>
          <ReactOwlCarousel
        items={1} 
        className="owl-theme" 
        loop={true}
        nav 
        dots={false}
        autoplay={true}
        autoplayTimeout={3000}
        >
          <div className='rd-carousel'>
            <Row style={{margin:'0px'}}>
             
              {/* <Col md="6" sm={12} xs={12}>
              <img src={ehr} className='keyf-img'/>
              </Col> */}
              <Col md="12" sm={12} xs={12}>
                <p className='p-keyf-heading'>Speciality Based EHR</p>
                <p className='p-keyf-content'>1. SOAP notes documentation is made easy with custom templates for all the specialties.<br/>
                2.Robust application for the providers to access the patient chart from any device.<br/>3.Workflow is made easy so that your staff can save time with 
less data entry and work in effective ways

                </p>
              </Col>
            
            </Row>
          </div>
          </ReactOwlCarousel>
          </Container>
          <br/><br/>
          <h4 className='p-keyf'>Features</h4>
          <br/>
          <Container>
          <Row style={{margin:'0px'}}>
          <Col md="1"></Col>
              <Col md="5" xs={12} sm={6}>   
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Free Implementation and Data migration from your legacy system</p>
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Unlimited trainings for you and your staff members</p>
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Easy scheduling process</p>
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Customized Patient Forms</p>
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;AI Infused Medical Billing</p>
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Speciality based EHR</p>
              </Col>
              <Col md="5" xs={12} sm={6}>
              
              <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Virtual care like Tele health,CCM and RPM readily available</p>
              <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;eRx and eLabs interface made simple</p>
              <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Credentialing support for new providers</p>
              <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Industry standard reports for free</p>

              </Col>
              <Col md="1"></Col>
          </Row>
          </Container>
          <br/>
          <br/>
          <h4 className='p-keyf'>Outcomes</h4><br/>
          <Container>
          <Row style={{margin:'0px'}}>
         
          <Col md="6" xs={12} sm={12}>
            <p className="p-outcome">Simple Workflow</p>
            <p className="p-outcome">Improved Collection</p>
            <p className="p-outcome">Increase Practice revenue</p>
            <p className="p-outcome">MIPS and MACRS Reporting</p>
            <p className="p-outcome">20% to 35% Cost Reduction</p>
            <p className="p-outcome">Key Performance Indicators</p>
          </Col>
          <Col md="6" xs={12} sm={12}>
        <img src={outcome} className='outcome-img'/>
          </Col>
          </Row>
          </Container>
      </div>
    )
  }
}

export default EHR