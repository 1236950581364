import React, { Component } from 'react'
import Navbar from '../Navbar'
import {Container} from 'react-bootstrap'
import '../../Styles/features.css'
export class Success extends Component {
  componentDidMount(){
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
         <Navbar color="#041b3d" textColor="white"/>
          <div className='company-top'>
           <p className='company-tophead' align='center'>Success in Proactive Denial Management and Prevention</p>
          </div><br/>
          <Container>
            <b><p className='f-head'>Tackling the causes of claim denials from the front end can help healthcare organizations reduce denials and increase the success rate of claims appeals.</p></b>
            <p className='f-content'>Despite changes in payment methodologies, claim denials cause sizeable revenue leakage for healthcare providers across the United States. According to recent research into denial rates reported in February, out of $3 trillion in total claims submitted by healthcare organizations, $262 billion were denied, translating to nearly $5 million in denials, on average, per provider. </p>
            <p className='f-content'>Providers typically do nothing at all to address more than half of denials. Up to 65 percent of denied claims are never resubmitted. b Included in this percentage are denials stemming from commercial health plans, which—according the previously cited research—constituted 58 percent of all denials in 2017, up from 54 percent in 2016. This is money left on the table, and the industry is still looking for solutions to tackle the issue.</p>
            <p className='f-content'>Some consequences of denials are expected, including increased days in accounts receivable (A/R), growth in write-off rates, increase cost to collect, and stagnant cash flow. But there are unexpected consequences too. Productivity standards may be perceived as unattainable; patient access, clinical, health information management (HIM), and patient financial service teams may argue about which group is responsible for the denials; supervisors and managers could ask for more staff, even though metrics do not indicate a need; and staff morale ultimately can decline.</p>
            <p className='f-content'>Instead of playing the blame game, teams must collaborate to identify the root causes of the provider’s denials. Providers are trying to identify, manage, and ultimately avoid denials to protect their revenue. Identifying root causes has been proven to help mitigate denials, confirming that issues can be reversed.</p>
            <p className='f-content'>Denials prevention requires all hands on deck. It requires cooperation and corrective actions at every point in the revenue cycle—patient access in the front, clinical services and HIM in the middle, and patient financial services in the back. Yet providers miss opportunities to mitigate denial risk from the beginning to the end of the revenue cycle—in scheduling, patient access, patient care, HIM, charge capture, coding, billing, and collections. Communication between the back and front ends of the revenue cycle also is a factor; often, poor communication increases the potential for denials. In the middle, special attention to documentation and medical coding can increase the likelihood of an approved claim.</p>
            <p className='f-content'>A healthcare organization’s efforts to mitigate denials should focus initially on identifying root causes with the goal of simplifying the appeals process and implementing effective preventive measures. Before entering a discussion of specific strategic steps and remedies, it can be helpful to explore key considerations associated with these three elements—i.e., root causes, appeals, and prevention.</p>
            <br/>
            <b className='f-head'>Root Causes</b><br/><br/>
            <p className='f-content'>Healthcare organizations first should pay close attention to and seek to correct some of the common hidden causes of denials.</p>
            <p className='f-content'><b>Disparate systems and processes:</b>&nbsp;Having multiple disjointed systems, processes, and workflows within a provider organization promotes inefficiency, and it can lead to untimely filing of denials and increased cost to collect. Such disjointedness associated with patient accounting systems (PAS) or electronic health record (EHR) system upgrades, mergers, or changes can cause a backlog in A/R. Denials pile up because the IT systems can’t talk to each other. Until data are merged from different systems into one centralized system, claims will likely be inaccurate or incomplete.</p>
            <p className='f-content'><b>Complex claims processing:</b>&nbsp;Two significant factors have caused claims processing to become increasingly more complex: the advent of the insurance marketplaces, which increased the number of insurance plans and products; and the proliferation of high deductible health plans, which have shifted a greater portion of financial responsibility to patients. The American Medical Association (AMA) estimates claims processing inefficiencies cost between $21 billion and $210 billion per year. c The reality is, as margins shrink, it is more difficult for health systems to absorb the cost of denials simply as a cost of doing business. Providers need to ensure that they collect on every dollar owed.</p>
            <p className='f-content'><b>Culprits hidden in plain sight:</b>&nbsp;There also are common problems that contribute to denials. Many systems lack even basic visibility into denials data and cannot diagnose performance bottlenecks, ultimately increasing time to denial resolution.</p>
            <p className='f-content'>Inadequate support for process improvement can cause denials. Quick fixes often are not sustainable. Many organizations not only have difficulty discovering what and where their denials are, but also do not report them correctly. To address these problems, these organizations first must understand that avoiding denials requires significant effort across the revenue cycle to address the root causes. Their goal should be to develop a prevention program that won’t overburden their teams, incorporating the guidance and recommendations outlined below.</p>
           <br/><p className='f-head'>Appeals</p><br/><br/>
            <p className='f-content'>Healthcare providers and health systems can recoup financial losses by appealing claim denials, but recent trends indicate that successfully appealing a denial has become more complicated for providers. According to a recent Advisory Board Survey, the median success rate for provider claim denial appeals dropped from 56 percent to 45 percent for private heath plans over the past two years. d The median success rate for claim denials appeals for Medicaid also dropped from 51 percent to 41 percent.</p>
            <p className='f-content'>Appeals are a headache. They are the longest and most expensive route to collect amounts due. For example, if a patient’s denial is appealed in the emergency department (ED), the organization’s cost for the appeal will be minimal, because it is relatively easy to identify care delivered within the short time frame of an ED visit. But if the cost of a patient’s care is denied throughout a lengthy hospital stay all the way up to the patient’s discharge, appealing that denial can be a battle, and the cost of that battle makes it more critical that it be won. And even if it is won, it will be the costliest way to collect on the denial.</p>
            <br/><p className='f-head'>Prevention</p><br/><br/>
            <p className='f-content'>The potentially high cost of appeals underscores the importance of implementing effective processes to prevent denial. The fact is that the further along the factors potentially causing a denial are allowed to progress through the care process, the more expensive the denial becomes.</p>
            <p className='f-content'>Taking preventive steps as early as possible in the revenue cycle therefore is a much less expensive approach.</p>
            <p className='f-content'>The good news is that 90 percent of all denials are preventable, and two-thirds of those preventable denials can be successfully appealed. e However, the remaining one-third of those denials represent missed opportunities for prevention, and the lost revenue cannot be recovered.</p>
            <p className='f-content'>Technology and analytics can help in preventing denials, but without sustainable process improvement, these tools provide only a fraction of the possible results hospitals can achieve. By reallocating resources to focus on prevention, the total amount of denials would decrease, and the efficiency of resources would improve, ultimately reducing the cost to collect. Imagine what could be accomplished if 90 percent of available resources used to respond to denials on the back end were used to prevent them earlier in the revenue cycle.</p>
            <br/><p className='f-head'>Strategies</p><br/><br/>
            <p className='f-content'>Healthcare organizations can employ several strategies to prevent denials.</p>
            <p className='f-content'><b>Look upstream:</b>&nbsp;The first step in addressing denials is to identify where they are occurring in the revenue cycle and why, because a provider must identify the root causes of denials to know where to begin preventing them.</p>
            <p className='f-content'>A good place to start looking for root causes is at beginning of the revenue cycle, because the earlier in the revenue cycle that an organization implements a preventive measure, the more efficient its effort to prevent denials will be. From the first encounter with the patient, many processes are broken that make even getting accurate demographic information a challenge. Anything from making errors and omissions during registration or providing a wrong address to lacking prior authorization can cause an insurer to reject a claim. For example, obtaining prior authorization for a procedure that ultimately requires another portion added to it can result in an authorization mismatch that can cause a claim to get rejected.</p>
            <p className='f-content'><b>Verify identity:</b>&nbsp;Upstream issues in areas such as scheduling, patient access, or care delivery can camouflage problems downstream, which is why root cause analysis is so important. A simple way for organizations to start eliminating denials is by verifying that patients are who they say they are. Simple information—address, occupation, insurance status, and coverage details (what is covered or not covered)—all can help to prevent a denial that might occur from having the wrong information in the claim.</p>
            <p className='f-content'>Providers can furnish accurate estimates to patients to establish their out-of-pocket expenses, informing the patients of their financial obligations up front. Patient access staff also should ensure that prior authorization requirements are fulfilled up front and that advanced beneficiary notices are on file to submit claims.</p>
            <p className='f-content'>It is important to determine who owns what and the best place to address these issues. For example, a provider can seek insurance authorization for a patient’s treatment at various points throughout the revenue cycle. Authorization can happen prior to scheduling a procedure, after scheduling and prior to performing a procedure, or as soon as a clinical situation arises that requires a procedure to be added to or changed. When case management collaborates with the physician to appropriately adjust patient status (observation to inpatient or inpatient to observation), clear guidelines should be in place identifying who on the team is authorized to make those changes, because authorization by the wrong person can result in a denial.</p>
            <p className='f-content'><b>Don’t underestimate HIM and the middle revenue cycle, including clinical documentation improvement:</b>&nbsp;Plenty can be done in the middle of the revenue cycle to prevent denials. HIM professionals play a key role integrating the provider’s clinical and financial processes to optimize payments across the continuum of care because they ensure complete clinical documentation is in place to accurately inform coding efforts and set appropriate payment expectations.</p>
            <p className='f-content'>Collaboration among stakeholders in every aspect of the revenue cycle is necessary to sustain improved financial outcomes and effective clinician engagement. Complete clinical documentation should not only lead to accurate coding, but also ensure the organization upholds the highest standards of revenue integrity and data management. Turning data into information and information into process improvements can eliminate leakage in the revenue cycle. One opportunity could come from changing upstream behaviors in patient access to ensure that providers are capturing everything needed to submit an accurate claim. Documentation practices can be augmented to ensure that providers are capturing a complete picture of services delivered and the patient’s clinical condition. Important steps included providing data to the clinicians to inform them of the impacts of certain clinical practices and feeding this information back to the contracting team to give them full visibility into impacts of contract charts.</p>
            <br/><p className='f-head'>Data And Analytics</p><br/><br/>
            <p className='f-content'>Data and analytics tools are crucial to finding the root cause. A 2016 HIMSS Analytics survey revealed that more than half of respondent hospitals still do not use denials management analytics or workflow tools. f Many other hospitals are still struggling to put the right tools in place. Thirty-one percent of hospitals manage denials manually using spreadsheets.</p>
            <p className='f-content'>Real-time analytics are essential to avoid reporting delays. It is common for organizations to rely on their central analytics team for reporting, but these teams often are overworked and can take weeks to produce requested insights, which creates bottlenecks. Receiving real-time data and making those data meaningful can help in tracking down the root cause of a denial. This information contributes to revenue integrity by facilitating charge description master reviews, charge capture, coding, coding audits, denial prevention, and checks on patient status.</p>
            <p className='f-content'>Healthcare organizations can use the data to dig into a handful of metrics, such as the following, to determine how they affect one another:</p>
            <p className='f-content'>
              <ul>
                <li>Initial denial rate</li>
                <li>Rate of appeals</li>
                <li>Win/Loss Ratio</li>
              </ul>
            </p>
            <p className='f-content'>To analyze how an organization is fighting and preventing denials, these three metrics must be reviewed in concert.</p>
            <p className='f-content'><b>Initial denial rate:</b>&nbsp;The more detail learned about the initial denial rate the better. In addition to industry standards that measure the denial rate at the account level, providers want to know they are looking at the rate of denial at the dollar level. That level of specificity provides the opportunity to dig up denial trends—location, size, and financial impact. It also helps prioritize both appeal and mitigating efforts.</p>
            <p className='f-content'><b>Rate of appeals:</b>&nbsp;Regarding the appropriate rate of appeal, consider that failing to appeal a denial will ensure the organization is not paid; however, fighting too many bad appeals puts an organization at risk of wasting money that could be spent on prevention efforts.</p>
            <p className='f-content'>Provider organizations across the United States whose denials prevention programs represent best practices practice typically appeal 85 to 88 percent of denials. Percentages above or below this range likely indicate upstream problems. Providers that appeal above 92 percent of denials are probably fighting too many losers while missing upstream opportunities to prevent denials. Meanwhile, a provider that is appealing a lower percentage of denials is sending a message to the insurer that it is not looking closely at the denials.</p>
            <p className='f-content'>The best practice is to appeal enough claims from each insurer to create an ample sharing of information. An insurer also needs to know where its opportunities lie, and must be confident that the provider has a mechanism in its appeals process for communicating with insurers, around appeal.</p>
            <p className='f-content'><b>Win/Loss Ratio</b>&nbsp;On its own, this ratio measures the reactive appeals success rate. To measure success in preventing denials, however, this ratio must be analyzed alongside the previous two metrics. Without this context, extremes in the win or loss rate can be misinterpreted. If the rate of appeal is too low, for example, the win rate will be artificially inflated, and if it is too high, the win rate will be artificially depressed.</p>
            <br/><p className='f-head'>Best Practices in Work Flow and Productivity Tracking</p><br/><br/>
            <p className='f-content'>To promote continuous improvement in preventing denials, a provider organization should involve all departments routinely in the denials prevention process. In preparing for the process, the organization should perform a stakeholder analysis to ensure all the right players have a defined role and to ascertain their views regarding potential changes. This effort might encounter resistance initially, but the stakeholders’ concerns can be allayed through monthly check-in meetings in which the stakeholders are invited to share their perspectives and engage in discussions of identified root cause issues and how they might best be corrected.</p>
            <p className='f-content'>The provider organization also should conduct monthly reviews of detailed data on claims that are not paid initially. To identify trends that can be avoided in the future, claims should be examined using the following data points:</p>
            <p className='f-content'>
              <ul>
                <li>By insurer, to identify insurers for special focus</li>
                <li>By denial reason, so reasons may be linked to revenue cycle areas where problems have tended to originate</li>
                <li>By insurer according to reason and service location, to show where opportunities lie within the facility footprint (the multiple locations in a health system) and within service lines.</li>
              </ul>
            </p>
            <p className='f-content'>Insurers are important participants in the conversation because they can benefit from the resolution of denials issues, and system efficiency may be achieved most effectively through provider-insurer collaboration.</p>
           <br/><p className='f-head'>Proactive Preparation for Payer Policy Changes</p><br/><br/>
            <p className='f-content'>Health insurers typically communicate policy changes (utilization review, clinical guidelines, payment, billing, and more) throughout the year through numerous formats, including by letter, newsletter, email notifications, and joint operating committee meetings. To stay current with insurer policies, the provider organization should monitor and be prepared for such policy changes. Policy update reviews and resulting communications should be timely so nothing is hidden or missing. Policy changes invariably affect all parties—including ordering providers, hospital departments, and the revenue cycle at every stage—and their related financial exposure.</p>
           <br/><p className='f-head'>Persistence Pays</p><br/><br/>
            <p className='f-content'>Denied claims are preventable if handled properly and early in the process. Building a successful and sustainable program that keeps an organization on track in preventing denials requires not only efficiency and collaboration, but also consistency in checking the data and analytics. To enable the organization to meet these requirements, the program also requires robust analytics, integrated technology with workflow automation functions, and an educated and empowered front-line staff. The solution must be tailored to the individual provider’s resources, workflows, and teams. To ensure the denials prevention program meets the organizations’ unique needs, close attention must be given to determining the right mix of technology, education, services, and advisory support.</p>
            <p className='f-content'>It’s time for healthcare organizations to lead the charge in changing the way the industry handles denials. By having a seamless program tailored its needs, a healthcare organization can contribute to reducing the burden of denials on the nation’s whole healthcare system, which ultimately helps clear the way for providers to focus on what is most important: caring for patients.</p>
           
            
          
          
          
          
          </Container>
      </div>
    )
  }
}

export default Success