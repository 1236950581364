import React, { Component } from 'react'
import {Row,Col, Card} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import '../../Styles/company.css'
import Navbar from '../Navbar';
export class Positions extends Component {
    constructor(props){
        super(props);
        this.state = {
            searchvalue:"",
            positions:[
                {
                    name:"Software Developer",
                    location:"Chennai",
                    description:"Software Developer"
                },
                {
                    name:"Software Engineer",
                    location:"Chennai",
                    description:"Software Engineer"
                },
                {
                    name:"Full Stack Developer",
                    location:"Chennai",
                    description:"Software Developer"
                },
                {
                    name:"Backend Developer",
                    location:"Chennai",
                    description:"Software Developer"
                },
                {
                    name:"Senior Marketing Associate",
                    location:"Chennai",
                    description:"Marketing"
                },
                {
                    name:"Marketing Manager",
                    location:"Chennai",
                    description:"Marketing"
                }
            ]
        }
    }
    componentDidMount(){
        window.scrollTo(0, 0)
      }
  render() {
    return (
      <div>
           <Navbar color="#041b3d" textColor="white"/>
            <div className='company-top'>
           <p className='company-tophead' align='center'>Let's Make Difference</p>
          </div>
          <input className="search-position" type="text" placeholder="Search Positions...." value={this.state.searchvalue} onChange={(e)=>this.setState({searchvalue:e.target.value})}></input>
          <br/><br/>
            <Row style={{margin:'0px'}}>
           
            {this.state.positions.map((data) => 
           
            <Card className="job-card" style={{width:'420px'}}>
             <Card.Body>
                    <p className="job-head" align="left">{data.name}</p>
                    <p className="job-location" align="left">{data.location}</p>
                    <p className="job-content" align="left">{data.description}</p>
                   <Link to="/apply-jobs" className='link'> <p className='apply-btn' align="left">Apply</p></Link>
             </Card.Body> 
            </Card>
          
             )}
             
            </Row>
            <br/>
            
      </div>
    )
  }
}

export default Positions