import React, { Component } from 'react'
import {Row,Col, Card, Container} from 'react-bootstrap'
import '../../Styles/services.css'
import { Timeline } from "react-beautiful-horizontal-timeline";
import '../../../node_modules/react-beautiful-horizontal-timeline/card.css'
import {AiOutlineDeploymentUnit} from 'react-icons/ai'
import {BsSearch,BsCheck2All} from 'react-icons/bs'
import {BiTargetLock} from 'react-icons/bi'
import {MdQuestionAnswer,MdFindReplace} from 'react-icons/md'
import {SiMicrostrategy,SiMaterialdesignicons} from 'react-icons/si'
import {FaProductHunt} from 'react-icons/fa'
import Navbar from '../Navbar'
import services from '../../Images/services.PNG'
import VerticalTimeline from 'react-time-line'
import {Link} from 'react-router-dom'
const data = [
  {
    s:"Ideation"
  },
  {
    s:"Deep Analysis",
    t:"on Feasibility"
  },
  {
    s:"Technology Solution",
    t:"to scale & sustain"
  },
  {
    s:"Conceptualize",
    t:"Strategy"
  },
  {
    s:"Product Definition"
  },
  {
    s:"Identfy Outliers"
  },
  {
    s:"Prototype &",
    t:"Design"
  },
  {
    s:"Extensive Testing"
  },
  {
    s:"Deployment"
  }
]

const list = [
  {text:'Ideation'},
  {text:'Deep Analysis on Feasibility'},
  {text:'Technology Solution to scale & sustain'},
  {text:'Conceptualize Strategy'},
  {text:'Product Definition'},
  {text:'Identfy Outliers'},
  {text:'Prototype & Design'},
  {text:'Extensive Testing'},
  {text:'Deployment'}
]
export class Services extends Component {
  componentDidMount(){
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
        <Navbar color="white" textColor="black" shadow="0 2px 5px rgba(0,0,0,.2)"/>
         <Row style={{margin:'0px'}} >
              <Col md="1"/>
              <Col md="8" xs={12} sm={12} className="top-row">
                <h4 className='h-heading'>Turn your automation goals into results</h4>
                <p className='h-content'>Whether you're just getting started with healthcare tech products or trying to fine tune how your company sets goals and execute them,our team is here to help.
                We offer workshops,multi-month coaching engagements, and on-demand support to help your organization acheive its automation objectives,faster.
                </p>
                <Link to='/contact-us' className='link'> <p className='h-getdemo'>Get Demo</p></Link>
              </Col>
              <Col md="3">
               <img src={services} style={{width:'100%'}} className='top-img'/>
              </Col>
          </Row><br/><br/>
          <h4 className='h-heading' align="center">How can we help?</h4><br/>
          <Container>
          <Row style={{margin:'0px'}}>
          <Col md="6" xs={12} sm={12}>
            <div className='s-card'>
            <h4 className='scroll-head'>Automation Readiness</h4>
            <p className='scroll-content'>If your company's leadership team isn't prepared to embark on your automation journey,we'll make sure your teasm gets there. Our OBO automation readiness workshop introduces the fundamentals of healtcare automation and the ingredients of success.
             We'll also follow up with you to make sure your team continues on the right path in healthcare automation products.</p>
            </div>
          </Col>
          <Col md="6" xs={12} sm={12}>
            <div className='s-card'>
            <h4 className='scroll-head'>On-demand strategy</h4>
            <p className='scroll-content'>Our strategic services consultants are available by the hour to help you work through any challenge you may be facing when it comes to your obo products or any healthcare products.
            They'll focus on identifying success tactics for your team and acts as a sounding board to help you achieve short and long-term results.</p>
            </div>
          </Col>
        </Row>
        </Container><br/>
        <Container>
          <Row style={{margin:'0px'}}>
            <Col md={6} xs={12} sm={12}>
             <div className='s-card'>
             <h4 className='scroll-head'>OBO Health Product Accelerator</h4>
             <p className='scroll-content'>New to healthcare and needing support? Our OBO health acceleration program will make sure you receive the support you need throughout your implementation process. Adopting a new way of working takes time and committment.We'll help make that transition easier and faster so your team can focus on business
             objectives instead of process and tech products.</p>
             </div>
            </Col>
            <Col md={6} xs={12} sm={12}>
              <div className='s-card'>
              <h4 className='scroll-head'>OBO Health Special Situation Consulting</h4>
              <p className='scroll-content'>Whether it's drafting healthcare products,turning around a struggling healthcare system,empowering intellectually safe work environments,strategic planning, or business rythm design,we have a Domain expert and Top Engineering Team designed to help you with specific needs in your need</p>
              </div>
            </Col>
          </Row>
        </Container><br/><br/><br/>
        <h4 className='h-heading' align='center'>How do we do it?</h4><br/>
        <Container>
        <Row style={{margin:'0px'}}>
          <Col md="4" xs={12} sm={12}>
            <div className='howdo-card'>
             <BiTargetLock className='howdo-icon'/><br/>
             <p className='howdo-head'>Ideation</p>
             <p className='h-content'>Ideation comprises all stages of a thought cycle, from innovation, to development, to actualization</p>
            </div>
          </Col>
          <Col md="4" xs={12} sm={12}>
          <div className='howdo-card'>
             <BsSearch className='howdo-icon'/><br/>
             <p className='howdo-head'>Deep Analysis</p>
             <p className='h-content'>Conducting a Market Feasibility Analysis for new products is a critical step in the product development process, sizing the potential market, and ultimately determining market feasibility</p>
            </div>
          </Col>
          <Col md="4" xs={12} sm={12}>
          <div className='howdo-card'>
             <MdQuestionAnswer className='howdo-icon'/><br/>
             <p className='howdo-head'>Technology Solution</p>
             <p className='h-content'>Scaling is the activity that gives the product sustainability and stability. A series of small but significant tested iterations that respond to, and anticipate, the market’s requirements</p>
            </div>
          </Col>
         
          
          </Row>

        </Container><br/>
        <Container>
        <Row style={{margin:'0px'}}>
        <Col md="4" xs={12} sm={12}>
          <div className='howdo-card'>
             <SiMicrostrategy className='howdo-icon'/><br/>
             <p className='howdo-head'>Conceptualize Strategy</p>
             <p className='h-content'>When conceptualizing a new idea, it is essential to direct the thinking to specific dimensions and search answers which helps to evolve the idea from the initial thought through the various stages of innovation</p>
            </div>
          </Col>
        <Col md="4" xs={12} sm={12}>
        <div className='howdo-card'>
             <FaProductHunt className='howdo-icon'/><br/>
             <p className='howdo-head'>Product Definition</p>
             <p className='h-content'>Define the core purpose.Let us work out the purpose of our product by asking a set of simple but revealing questions</p>
            </div>
            </Col>
            <Col md="4" xs={12} sm={12}>
            <div className='howdo-card'>
             <MdFindReplace className='howdo-icon'/><br/>
             <p className='howdo-head'>Identify outliers</p>
             <p className='h-content'>Outliers are extreme values that deviate from other observations on data , they may indicate a variability in a measurement, experimental errors or a novelty</p>
            </div>
          </Col>
         
          </Row>

        </Container><br/>
        <Container>
        <Row style={{margin:'0px'}}>
        <Col md="4" xs={12} sm={12}>
          <div className='howdo-card'>
             <SiMaterialdesignicons className='howdo-icon'/><br/>
             <p className='howdo-head'>Prototype & Design</p>
             <p className='h-content'>Prototyping is an experimental process where design teams implement ideas into tangible forms from paper to digital.With prototypes, you can refine and validate your designs so your brand can release the right products</p>
            </div>
          </Col>
          <Col md="4" xs={12} sm={12}>
          <div className='howdo-card'>
             <BsCheck2All className='howdo-icon'/><br/>
             <p className='howdo-head'>Extensive Testing</p>
             <p className='h-content'>This solution is an open and generic test automation framework designed to make integration, validation, regression and end-to-end testing with a collaborative workspace environment</p>
            </div>
          </Col>
        <Col md="4" xs={12} sm={12}>
        <div className='howdo-card'>
             <AiOutlineDeploymentUnit className='howdo-icon'/><br/>
             <p className='howdo-head'>Deployment</p>
             <p className='h-content'>Software deployment is all of the activities that make a software system available for use. The general deployment process consists of several interrelated activities with possible transitions between them</p>
            </div>
            </Col>
           
          </Row>

        </Container><br/><br/><br/>
        <Row style={{margin:'0px'}}>
         <Col md="1"/>
         <Col md="10" xs={12}>
         <h4 className='h-heading' align="center">Our Process</h4>
            <p className='h-content' align="center">
              Our approach to solve your health care automation business problem towards the next breakthrough.
              Attain the outcomes that are most critical to help you run and transform your business through our unique set of processes.
            </p> 
         </Col>
         <Col md="1"/>
          </Row>
          {/* <div className='horizontal-timeline'> */}
          <Timeline
        myList={data}
        labelWidth={150}
        lineColor="#1d60e3"
        eventTextAlignCenter={true}
        showSlider={false}
        amountMove={350}
       
      />
        
          {/* </div> */}
          <div className='vertical-timeline'>
            <VerticalTimeline
            items={list}
            
            />
          </div>
     
      <Container>
      <h4 className="h-heading" align="center">Empowering to scale, sustain and flourish with our Deep Thinking AI infused solutions</h4>
      <Link to='/contact-us' className='link'><p className='getdemo-center' align="center">Get Demo</p></Link>
      </Container>
      </div>
    )
  }
}

export default Services