import React, { Component } from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
// import Post from "./Post";
import bot from "../Images/bot.png"


// all available config props
const config = {
  width: "400px",
  height: "500px",
  floating: true,
};

// all available theme props
const theme = {
  background: "#f5f8fb",
  fontFamily: "Helvetica Neue",
  headerBgColor: "#041b3d",
  botFontColor: "#000000",
  userBubbleColor: "#43A1ED",
  headerFontColor: "#fff",
  headerFontSize: "15px",
  botBubbleColor: "#D3D3D3",
  userFontColor: "#fff",
 
};

class Chatbot extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <ChatBot
            // hideHeader={true}
          //  headerComponent={false}
          //  headerTitle={false}
          floatingIcon={bot}
          hideBotAvatar={true}
          hideUserAvatar={true}
          headerTitle={"Chat Bot"}
          steps={[
            {
              id: "intro",
              message:
                "Hey there! Are you intrested in obo?",
              trigger: "user",
            },
            {
              id: "user",
              options: [
                { value: "y", label: "Yes", trigger: "yes-response" },
                { value: "n", label: "No", trigger: "no-response" },
              ],
            },
            {
              id: "yes-response",
              message: "Great!",
              trigger: "join",
            },

            {
              id: "no-response",
              message: "Sorry to hear that.",
              trigger: "continue",
            },

            {
              id: "join",
              message: "Do you want to join with us?",
              trigger: "response",
            },
            {
              id: "response",
              options: [
                { value: "y", label: "Yes", trigger: "greet-again" },
                { value: "n", label: "No", trigger: "no-response" },
              ],
            },
            {
              id: "greet-again",
              message: "That's really great!",
              trigger: "q-name",
            },
            {
              id: "q-name",
              message: "Let me know what's your name?",
              trigger: "name",
            },
            {
              id: "name",
              user: true,

              validator: (value) => {
                if (
                  /^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*/.test(value)
                ) {
                  return true;
                } else {
                  return "Please input alphabet characters only.";
                }
              },
              trigger: "q-email",
            },
            {
              id: "q-email",
              message: "Could I have your business email id ?",
              trigger: "email",
            },
            {
              id: "email",
              user: true,
              validator: (value) => {
                if (
                  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
                ) {
                  return true;
                } else {
                  return "Please enter a valid email.";
                }
              },
              trigger: "q-phone",
            },
            {
              id: "q-phone",
              message: "Could you share your phone number?",
              trigger: "phone",
            },
            {
              id: "phone",
              user: true,
              validator: (value) => {
                if (
                  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
                    value
                  )
                ) {
                  return true;
                } else {
                  return "Please enter a valid phone number.";
                }
              },
              trigger: "greet",
            },

            {
              id: "greet",
              message: "Thanks for the details! ",
              trigger: "q-submit",
            },

            {
              id: "q-submit",
              message: "Do you wish to submit?",
              trigger: "submit",
            },
            {
              id: "submit",
              options: [
                { value: "e", label: "Yes", trigger: "end-message" },
                { value: "f", label: "No", trigger: "no-submit" },
              ],
            },
            {
              id: "no-submit",
              message: "Your information was not submitted.",
              trigger: "continue",
            },
            {
              id: "continue",
              message: "Do you want me to continue?",
              trigger: "end-options",
            },
            {
              id: "end-options",
              options: [
                { value: "g", label: "Yes", trigger: "intro" },
                { value: "h", label: "No", trigger: "end" },
              ],
            },
            {
              id: "end",
              message: "Thank You!",
              end: true,
            },
            {
              id: "end-message",
              // component: <Post />,
              message: "Do you want me to continue?",
              // asMessage: true,
              end: true,
            },
          ]}
          {...config}
        />
      </ThemeProvider>
    );
  }
}

export default Chatbot;
