import React, { Component } from 'react'
import {Row,Col,Card, Container} from 'react-bootstrap'
import '../Styles/wefollow.css'
import {BsArrowRight} from 'react-icons/bs'
import {GiProgression} from 'react-icons/gi'
import {GiAutomaticSas} from 'react-icons/gi'
import {MdOutlineDashboardCustomize} from 'react-icons/md'
import {MdTouchApp} from 'react-icons/md'
import {FaFileInvoiceDollar} from 'react-icons/fa'
import {Link} from 'react-router-dom'
export class WeFollow extends Component {
  render() {
    return (
        <div style={{backgroundColor:"#041b3d"}}>
            <br/>
          
      <h4 className="h-heading" align='center' style={{color:"white"}}>We follow simple pricing & hassel free customizable</h4>
      <br/>
            
          <Container>
          <Row style={{margin:'0px',marginTop:"5%"}}>
         
          <Col md="6" xs={12} sm={6}>
            <div className='card-follow'>
            <div className="follow-icon-container1">
              <MdTouchApp  style={{ color:'#f59510', height:"30px",width:"30px",
                            marginTop: "20%",marginLeft:"20%"
                          }}/>
              </div>
             
                <p className='follow-head'>One Click</p>
                <p className='follow-content'>One click to Try or purchase our software directly feel the automation in your business from day one</p>
                <Link to="/contact-us" className='link'><p className="learnmore">Learn More&nbsp;&nbsp;<BsArrowRight className="learnmore__icon"/></p></Link>  
            </div>
          </Col>
          <Col md="6" xs={12} sm={6}>
          <div className='card-follow'>
             
              <div className="follow-icon-container2">
              <FaFileInvoiceDollar style={{ color: "#095a75", height:"30px",width:"30px",
                            marginTop: "20%",marginLeft:"20%"
                          }}/>
              </div>
             
                <p className='follow-head'>Choose your pricing plan</p>
                <p className='follow-content'>Our pricing plans are designed for modern business use cases and affordable for organizations of all sizes.</p>
             <Link to="/contact-us" className='link'><p className="learnmore">Learn More&nbsp;&nbsp;<BsArrowRight className="learnmore__icon"/></p></Link>  

            </div>
          </Col>
          </Row>
          </Container>
          <Container>
          <Row style={{margin:'0px'}}>
          <Col md="6" xs={12} sm={6}>
          <div className='card-follow'>
          <div className="follow-icon-container3">
              <MdOutlineDashboardCustomize  style={{ color: "#653b74", height:"30px",width:"30px",
                            marginTop: "20%",marginLeft:"20%"
                          }}/>
              </div>
             
                <p className='follow-head'>Customize as you go </p>
              <p className='follow-content'>Extend and tailor experiences to meet your unique business needs, with low-code development and fast</p>
                <Link to="/contact-us" className='link'><p className="learnmore">Learn More&nbsp;&nbsp;<BsArrowRight className="learnmore__icon"/></p></Link>  
            </div>
          </Col>
          <Col md="6" xs={12} sm={6}>
          <div className='card-follow'>
             
              <div className="follow-icon-container4">
              <GiAutomaticSas  style={{ color: "#dd5524", height:"30px",width:"30px",
                            marginTop: "20%",marginLeft:"20%"
                          }}/>
              </div>
                <p className='follow-head'>Automation from day one</p>
                <p className='follow-content'>Accelerate your business productivity and efficiency with modern automation and collaboration tools, to get tangible results in no time.</p>
                <Link to="/contact-us" className='link'><p className="learnmore">Learn More&nbsp;&nbsp;<BsArrowRight className="learnmore__icon"/></p></Link>  
            </div>
          </Col>
         
          </Row>
          </Container>
          <br/>
          <br/>
          <br/>
        


        
        </div>
    )
  }
}

export default WeFollow;