import React, { Component } from 'react'
import {Row,Col,Container} from 'react-bootstrap'
import {GrPlan} from 'react-icons/gr'
import {MdOutlineDeveloperBoard} from 'react-icons/md'
import {BsFillArrowUpRightSquareFill,BsFillFileBarGraphFill,BsGraphUp,BsExclamationOctagonFill,BsCheckLg,BsSearch} from 'react-icons/bs'
import '.././Styles/solveproblems.css'
import solve from '../Images/solve.webp'
export class Solveproblems extends Component {
  render() {
    return (
      <div>

<Container>
       <div className="whychoose-container">
          <div className="whychoose-1">
            <Row style={{ margin: "0px", paddingTop: "5%" }}>
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <div class="circle">
                  <BsSearch
                    size={23}
                    style={{
                      fontWeight: "lighter",
                      verticalAlign: "middle",
                      marginTop: "23%",
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ paddingTop: "13%" }}>
              <Col className="whychoose-head">Analyse</Col>
            </Row>
            <Row style={{ paddingTop: "5.5%" }}>
              <Col md="12">
                <p style={{ fontSize: "13.5px", lineHeight: "1.5" }}>
                Understand the root cause
                </p>
              </Col>
            </Row>
          </div>
          <div className="whychoose-2">
            <Row style={{ margin: "0px", paddingTop: "5%" }}>
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <div class="circle">
                  <GrPlan
                    size={23}
                    style={{
                      fontWeight: "lighter",
                      verticalAlign: "middle",
                      marginTop: "23%",
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ paddingTop: "13%" }}>
              <Col className="whychoose-head">Plan</Col>
            </Row>
            <Row style={{ paddingTop: "5.5%" }}>
              <Col md="12">
                <p style={{ fontSize: "13.5px", lineHeight: "1.5" }}>
                Determine how to resolve the problem
                </p>
              </Col>
            </Row>
          </div>
          <div className="whychoose-3">
          <Row style={{ margin: "0px" }}>
            <Col >
          <div
              style={{
                textAlign: "center",
                fontWeight: "800",
                fontSize: "35px",
                color: "#202B5D",
                letterSpacing: "1.7px",
              }}
            >
              Solving problems with data
            </div>
            </Col>
            </Row>
          <Row style={{ margin: "0px" }}>
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <img src={solve} alt="why choose us" className='solve-img'/>
                </Col>
                </Row>
            {/* <div
              style={{
                textAlign: "left",
                fontWeight: "800",
                fontSize: "35px",
                color: "#202B5D",
                letterSpacing: "1.7px",
              }}
            >
              Why Choose Us
            </div>
            <Row>
              <Col
                style={{
                  textAlign: "left",
                  paddingTop: "3%",
                  fontSize: "15.5px",
                  lineHeight: "2",
                }}
              >
                At DigiNadu, we believe in doing things differently. We believe
                in coming up with ideas and strategies that are unique and in
                line with your brand. We aim to bring your brand to life by
                weaving your brand story and connecting it with your audience.
                Our goal, as a digital marketing and technology company, is to
                come up with high-end proposals that will transform and boost
                your brand.
                <br />
                <br />
                We, at DigiNadu, are a young team of creative, vibrant
                professionals that come together to give you an experience like
                none other. We ensure that you are as much a part of the team as
                any other member, and together we aim to create interactive
                content that generates the desired results.
              </Col>
            </Row>
            <Row style={{ paddingTop: "10%" }}>
              <Col>
                <button
                  style={{
                    borderRadius: "50px",
                    display: "flex",
                    justifyContent: "left",
                    background: "#202B5D",
                  }}
                >
                  Explore more
                </button>
              </Col>
            </Row> */}
          </div>
          <div className="whychoose-4">
            <Row style={{ margin: "0px", paddingTop: "5%" }}>
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <div class="circle">
                  <MdOutlineDeveloperBoard
                    size={23}
                    style={{
                      fontWeight: "lighter",
                      verticalAlign: "middle",
                      marginTop: "23.6%",
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ paddingTop: "13%" }}>
              <Col className="whychoose-head">Implement</Col>
            </Row>
            <Row style={{ paddingTop: "5.5%" }}>
              <Col md="12">
                <p style={{ fontSize: "13.5px", lineHeight: "1.5" }}>
                Put the resolution in place
                </p>
              </Col>
            </Row>
          </div>
          <div className="whychoose-5">
            <Row style={{ margin: "0px", paddingTop: "5%" }}>
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <div class="circle">
                  <BsCheckLg
                    size={23}
                    style={{
                      fontWeight: "lighter",
                      verticalAlign: "middle",
                      marginTop: "24%",
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ paddingTop: "13%" }}>
              <Col className="whychoose-head">Evaluate</Col>
            </Row>
            <Row style={{ paddingTop: "5.5%" }}>
              <Col md="12">
                <p style={{ fontSize: "13.5px", lineHeight: "1.5" }}>
                Determine if the resolution is producing the desired results
                </p>
              </Col>
            </Row>
          </div>
        </div>
        
      

       </Container><br/><br/>
      </div>
    )
  }
}

export default Solveproblems