import React, { Component } from 'react'
import { Container, Row,Col } from 'react-bootstrap'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import {Link} from 'react-router-dom'
import Navbar from '../Navbar'
export class StartTrial extends Component {
  componentDidMount(){
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
           <Navbar color="#041b3d" textColor="white"/>
            <div className='company-top'>
                <p className='starttrial-head' align='left'>Start from today and save hours from the process</p>
            </div>
            <Container>
              <Row style={{margin:'0px'}}>
                <Col md="4" xs={12} sm={12}>
                <p className="trial-cat">Elite</p>
                <div className="starttrial-card">
                  <p className='trial-dollar'>24$</p>
                  <p className='h-content'>Description</p>
                  <p className='h-content'><BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Amet millenum</p>
                  <p className='h-content'><BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Amet millenum</p>
                  <p className='h-content'><BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Amet millenum</p>
                  <p className='h-content'><BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Amet millenum</p>
                  <Link to='/contact-us' className='link'> <p className='contact-btn'>Contact sales</p></Link>
                </div>
                </Col>
                <Col md="4" xs={12} sm={12}>
                <p className="trial-cat">Enterprise</p>
                <div className="starttrial-card">
                  <p className='trial-dollar'>40$</p>
                  <p className='h-content'>Description</p>
                  <p className='h-content'><BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Amet millenum</p>
                  <p className='h-content'><BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Amet millenum</p>
                  <p className='h-content'><BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Amet millenum</p>
                  <p className='h-content'><BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Amet millenum</p>
                  <Link to='/contact-us' className='link'> <p className='contact-btn'>Contact sales</p></Link>
                </div>
                </Col>
                <Col md="4" xs={12} sm={12}>
                <p className="trial-cat">Business</p>
                <div className="starttrial-card">
                  <p className='trial-dollar'>80$</p>
                  <p className='h-content'>Description</p>
                  <p className='h-content'><BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Amet millenum</p>
                  <p className='h-content'><BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Amet millenum</p>
                  <p className='h-content'><BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Amet millenum</p>
                  <p className='h-content'><BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Amet millenum</p>
                  <Link to='/contact-us' className='link'> <p className='contact-btn'>Contact sales</p></Link>
                </div>
                </Col>
              </Row>
            </Container>
           

      </div>
    )
  }
}

export default StartTrial