import React, { Component } from 'react'
import { Row ,Col,Button,Card, Container} from 'react-bootstrap'
import logo from '../../Images/logowhite.PNG'
import trends from '../../Images/trends.PNG'
import {GrPlan} from 'react-icons/gr'
import {MdOutlineDeveloperBoard} from 'react-icons/md'
import {BsFillArrowUpRightSquareFill,BsFillFileBarGraphFill,BsGraphUp,BsExclamationOctagonFill,BsCheckLg,BsSearch} from 'react-icons/bs'
import {BiTimeFive} from 'react-icons/bi'
import {AiFillDollarCircle} from 'react-icons/ai'
import {HiDocumentReport} from 'react-icons/hi'
import {FiAlertTriangle} from 'react-icons/fi'
import {RiHealthBookFill} from 'react-icons/ri'
import '../../Styles/home.css'
import Tabs, {Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import albert from '../../Images/albert.jpg'
import tech from '../../Images/tech.jpeg'
import home from '../../Images/services.PNG'
import {Carousel} from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Avatar from 'react-avatar'
import Navbar from '../Navbar'
import Rendermenu from './Rendermenu'
import f1 from '../../Images/f1.jpg'
import f2 from '../../Images/f2.jpg'
import Bounce from 'react-reveal/Bounce'
import Flip from 'react-reveal/Flip'
import outcome from '../../Images/outcome.PNG'
import f3 from '../../Images/f3.jpg'
import sticker from '../../Images/sticker.png'
import Zoom from 'react-reveal/Zoom'
import WeFollow from '../WeFollow'
import {Link} from 'react-router-dom'
import think from '../../Images/ai3.jpg'
import glasses from '../../Images/glasses.png'
import p1 from '../../Images/p1.png'
import p2 from '../../Images/data.jpeg'
import p3 from '../../Images/p3.png'
import ReactOwlCarousel from 'react-owl-carousel'
import Solveproblems from '../Solveproblems'
import fe1 from '../../Images/fe1.webp'
import robo from '../../Images/robo.webp'
class Home extends Component {
  constructor(props){
    super(props);
    this.state = {
      index:[1,2,3,4,5,6,7]
    }
  }
  componentDidMount(){
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
          <Navbar color="white" textColor="black" shadow="0 2px 5px rgba(0,0,0,.2)"/>
          <Row style={{margin:'0px'}}>
              <Col md="1"/>
              <Col md={7} sm={12} xs={12} className="top-row">
                <ReactOwlCarousel
                 items={1} 
                 className="owl-theme" 
                 loop={true}
                 dots={false}
                 autoplay={true}
                 autoplayTimeout={3000}
                >
                  <div className='rd-carousel'>
                  <h4 className='h-heading'>Lesser Human Validation &<br/>Management</h4>
                <p className='h-content'>At OBO Health, we hatch the ideas generated into models, products that help healthcare industries to be more potent in 
                terms of scaling, effective managing, and sustaining the business than ever before
                </p>
                <Link to='/contact-us' className='link'> <p className='h-getdemo'>Get Demo</p></Link>
                  </div>
                  <div className='rd-carousel'>
                  <h4 className='h-heading'>AI infused software product to solve healthcare problems</h4>
                <p className='h-content'>To achieve our mission of creating a better model of healthcare, we're combining AI and technology with human expertise
                </p>
                <Link to='/contact-us' className='link'> <p className='h-getdemo'>Get Demo</p></Link>
                  </div>
                </ReactOwlCarousel>
              
              </Col>
              <Col md={4} xs={12} sm={12}>
                <img src={home} style={{width:'100%'}} className='top-img'/>
              </Col>
          </Row><br/>
          <h4 className='h-heading' align='center'>OBO Product shakes it up with this formula!</h4><br/>   
          <Container>
           <Row style={{margin:'0px'}}>
            <Col md="4" xs={12} sm={12}>
            <div className='card-formula'>
            <p className='formula-number'>01</p>
            <p className='formula-head'>Analysis&nbsp;</p>
            <p className='formula-content'>Analyse the healthcare automation problem in the markets faced by the user</p>
            </div>
            </Col>
            <Col md="4" xs={12} sm={12}>
            <div className='card-formula'>
            <p className='formula-number'>02</p>
            <p className='formula-head'>Actions&nbsp;</p>
            <p className='formula-content'>Our team take action to build AI infused software product around the problem</p>
            </div>
            </Col>
            <Col md="4" xs={12} sm={12}>
            <div className='card-formula'>
            <p className='formula-number'>03</p>
            <p className='formula-head'>Automation&nbsp;</p>
            <p className='formula-content'>Delivering the product which solve the automation problems in the healthcare market</p>
            </div>
            </Col>
           </Row>
          </Container>
          <Row style={{margin:'0px'}}>
         <Col md="1"/>
         <Col md="10" xs={12} sm={12}>
         <h4 className='h-heading' align="center">Products for every challenge</h4>
            <p className='h-content' align="center">While human cognitive ability is invaluable to the system, our technology solutions will enable the human labor force to perform much more effectively and efficiently are the need of the hour. Our objective is to help curtail the expenditure and stimulate the Medical Fraternity's financials.</p> 
         </Col>
         <Col md="1"/>
          </Row><br/>
          <Container>
          <Row style={{margin:'0px'}}>
           <Col md="6" xs={12} sm={12}>
             <Row style={{margin:'0px'}}>
              <Col md="6">
                <Link to='/products/denial-and-ars-platform' className='link'>
              <div className='h-pro-card'>
               <p className='vertical-menu'><RiHealthBookFill className='h-pro-icon'/>&nbsp;&nbsp;DENIAL AND ARS PLATFORM</p>
               <p className='h-content'>Say Good-Bye to calling the insurance to obtain the status of the claims</p>
              </div>
              </Link>
              </Col>
              <Col md="6">
              <Link to='/products/radiology' className='link'>
              <div className='h-pro-card'>
               <p className='vertical-menu'><RiHealthBookFill className='h-pro-icon'/>&nbsp;&nbsp;RADIOLOGY</p>
               <p className='h-content'>OBO's - Radiology Platform has been our initial successful product</p>
              </div>
              </Link>
              </Col>
             </Row>
             <Row style={{margin:'0px'}}>
              <Col md="6">
              <Link to='/products/ehr' className='link'>
              <div className='h-pro-card'>
               <p className='vertical-menu'><RiHealthBookFill className='h-pro-icon'/>&nbsp;&nbsp;EHR</p>
               <p className='h-content'>Complete cloud based application customized for all specialities</p>
              </div>
              </Link>
              </Col>
              <Col md="6">
                <Link to='/products/er-ed' className='link'>
              <div className='h-pro-card'>
               <p className='vertical-menu'><RiHealthBookFill className='h-pro-icon'/>&nbsp;&nbsp;E/R,ED</p>
               <p className='h-content'>OBO’s – ER | ED Platform is the latest achievement to our success</p>
              </div></Link>
              </Col>
             </Row>
             <Row style={{margin:'0px'}}>
              <Col md="6">
              <div className='h-pro-card'>
               <p className='vertical-menu'><RiHealthBookFill className='h-pro-icon'/>&nbsp;&nbsp;PMS</p>
               <p className='h-content'>We are working on the full version of our product and will be launching soon! Stay tuned!</p>
              </div>
              </Col>
             
             </Row>
           </Col>
          <Col md="6" xs={12} sm={12}>
              <img src={outcome} className='outcome-img outcome-res'/>
            </Col>
          </Row><br/>
          </Container>
            
      <Solveproblems/>
          
         <br/>
          <h4 className='h-heading' align='center'>Our Idea Genaration started with <br/> "WHAT IF"</h4><br/><br/>
          <br/>
          <Container>
            <Row style={{margin:'0px'}}>
              <Col md="4" xs={12} sm={6}>
                <Row style={{margin:'0px'}}>
                <Col md="12">
                <img src={robo} className='emoji-img'/>
                </Col>  
                </Row><br/>
                <br/>
                <br/>
                 <br/>
                <Row style={{margin:'0px'}}>
                  <div className='whatif-top'>
                <Col md="3"/>
                <Col md="9" sm={12} xs={12}>
                <Flip top>
                    <p align="left" className='wi-second'><img src={sticker} className='sticker'/>&nbsp;What if we have a solution that <b>produces sustained and consistent cash flow</b></p>
                  </Flip>
                </Col> 
                </div> 
               
                </Row>
              </Col>
              <Col md="8" xs={12} sm={12}>
              <div className='whatif-res'>
                <Col md="12" sm={12} xs={12}>
                <Flip top>
                    <p align="left" className='wi-content'><img src={sticker} className='sticker'/>&nbsp;What if we have a solution that <b>produces sustained and consistent cash flow</b></p>
                  </Flip>
                </Col> 
                </div>
                <Row style={{margin:'0px'}}>
                  <Col md="4" xs={12} sm={12}>
                    <Flip top>
                    <p align="left" className='wi-content'><img src={sticker} className='sticker'/>&nbsp;What if we have a solution to <b>prevent denials and predict collections</b></p>
                    </Flip>
                  </Col>
                  <Col md="4" xs={12} sm={12}>
                  <Flip top>
                    <p align="left" className='wi-content'><img src={sticker} className='sticker'/>&nbsp;What if we have a solution that<b> saves time to hospitals</b> providers and provider groups to work on denials</p>
                  </Flip>
                  </Col>
                  <Col md="4" xs={12} sm={12}>
                  <Flip top>
                    <p align="left" className='wi-content'><img src={sticker} className='sticker'/>&nbsp;What if we have a solution that<b> reduces labour cost by 50% </b>employed for validation, reporting and management</p>
                  </Flip>
                  </Col>
                  </Row><br/><br/>
                  <Row style={{margin:'0px'}}>
                  
                  <Col md="4" xs={12} sm={12}>
                  <Flip top>
                    <p align="left" className='wi-content'><img src={sticker} className='sticker'/>&nbsp;What if we have a solution that <b>reduces the time to work on denial by 50%</b></p>
                  </Flip>
                  </Col>
                  <Col md="4" xs={12} sm={12}>
                  <Flip top>
                    <p align="left" className='wi-content'><img src={sticker} className='sticker'/>&nbsp;After Deep Research and Analysis,we believe our <b>"WHAT IF" transpired to "WHY NOT"</b></p>
                  </Flip>
                  </Col>
                  <Col md="4" xs={12} sm={12}>
                  <Flip top>
                    <p align="left" className='wi-content'><img src={sticker} className='sticker'/>&nbsp;With great thinkers and passionate people around, <b>"WHY NOT" is now a reality</b></p>
                  </Flip>
                  </Col>
                  </Row><br/><br/>
                  

              </Col>
            </Row>
          </Container>
          {/* <h4 className='h-heading' align='center'>Our Idea Genaration started with this <br/>one word "WHAT IF"</h4><br/>
            <Container> */}
            {/* <div className='wrapper'>
              <Row style={{margin:'0px'}}>
               
                <Col md="12">
               
                    <div className='parallax'>
                    <section>
                      <Container>
                      <Row style={{margin:'0px'}}>
                    <Col md="6">
                    <Fade top>
                     <img src="https://img.freepik.com/free-vector/data-extraction-concept-illustration_114360-4766.jpg?w=740" loading="eager" sizes="100vw"  alt="" className='ani-img'/> 
                  </Fade>
                    </Col>
                    <Col md="6">
                  
                    <p className='number'>1</p><br/>
                    <p className='ani-content'>What if we have a solution to prevent denials and predict collections</p>
                 
                    </Col>
                  </Row>
                      </Container>
                 
                  </section>
                 
                  <section>
                  <Container>
                      <Row style={{margin:'0px'}}>
                    <Col md="6">
                    <Fade top>
                     <img src="https://img.freepik.com/free-vector/human-hand-holding-stopwatch_74855-15463.jpg?size=338&ext=jpg" loading="eager" sizes="100vw"  alt="" className='ani-img'/> 
                  </Fade>
                    </Col>
                    <Col md="6">
                  
                    <p className='number'>2</p><br/>
                    <p className='ani-content'>What if we have a solution that saves time to hospitals, providers and provider groups to work on denials</p>
                 
                    </Col>
                  </Row>
                      </Container>
              
                  </section>
                  <section>
                  <Container>
                      <Row style={{margin:'0px'}}>
                    <Col md="6">
                    <Fade top>
                     <img src="https://img.freepik.com/free-vector/people-analyzing-growth-charts_23-2148866843.jpg?size=626&ext=jpg" loading="eager" sizes="100vw"  alt="" className='ani-img'/> 
                  </Fade>
                    </Col>
                    <Col md="6">
                  
                    <p className='number'>3</p><br/>
                    <p className='ani-content'>What if we have a solution that reduces labour cost by 50% employed for validation, reporting and management</p>
                 
                    </Col>
                  </Row>
                      </Container>
    
                  </section>
                  <section>
                  <Container>
                      <Row style={{margin:'0px'}}>
                    <Col md="6">
                    <Fade top>
                     <img src="https://img.freepik.com/free-vector/investor-with-laptop-monitoring-growth-dividends-trader-sitting-stack-money-investing-capital-analyzing-profit-graphs-vector-illustration-finance-stock-trading-investment_74855-8432.jpg?size=626&ext=jpg" loading="eager" sizes="100vw"  alt="" className='ani-img'/> 
                  </Fade>
                    </Col>
                    <Col md="6">
                  
                    <p className='number'>4</p><br/>
                    <p className='ani-content'>What if we have a solution that produces sustained and consistent cash flow</p>
                 
                    </Col>
                  </Row>
                      </Container>
                  </section>
                  <section>
                  <Container>
                      <Row style={{margin:'0px'}}>
                    <Col md="6">
                    <Fade top>
                     <img src="https://img.freepik.com/free-vector/businessman-stamping-notary-approving-documents_51635-1022.jpg?size=338&ext=jpg" loading="eager" sizes="100vw"  alt="" className='ani-img'/> 
                  </Fade>
                    </Col>
                    <Col md="6">
                  
                    <p className='number'>5</p><br/>
                    <p className='ani-content'>What if we have a solution that reduces time to work on denial by 50%</p>
                 
                    </Col>
                  </Row>
                      </Container>
                  </section>
                  <section>
                  <Container>
                      <Row style={{margin:'0px'}}>
                    <Col md="6">
                    <Fade top>
                     <img src="https://img.freepik.com/free-vector/business-performance-analysis-with-graphs_53876-66260.jpg?size=626&ext=jpg" loading="eager" sizes="100vw"  alt="" className='ani-img'/> 
                  </Fade>
                    </Col>
                    <Col md="6">
                  
                    <p className='number'>6</p><br/>
                    <p className='ani-content'>After Deep Research and Analysis, we believe our “WHATIF” transpired to “WHY-NOT”</p>
                 
                    </Col>
                  </Row>
                      </Container>
                  </section>
                  <section className='last-ani'>
                  <Container>
                      <Row style={{margin:'0px'}}>
                    <Col md="6">
                    <Fade top>
                     <img src="https://img.freepik.com/free-vector/light-bulb-with-title-great-idea_89224-1227.jpg?size=338&ext=jpg" loading="eager" sizes="100vw"  alt="" className='ani-img'/> 
                  </Fade>
                    </Col>
                    <Col md="6">
                  
                    <p className='number'>7</p><br/>
                    <p className='ani-content'>With great thinkers and passionates around, “WHYNOT” is now a reality!</p>
                 
                    </Col>
                  </Row>
                      </Container>
                  </section>

                  {/* </div> */}
                 
                {/* </Col>
              </Row>
              </div>
              </Container>  */}
                
          <h4 className='h-heading' align='center'>Why Choose our Products?</h4>
          <Row style={{margin:'0px'}}>
         
         <Col md="2"/>
         <Col md="3" xs={12} sm={12}>
         <Bounce left>
         <p className='why-card'> <BsFillFileBarGraphFill className='why-icon'/><br/><br/>Predict your denials and collections</p>
         </Bounce>
         </Col>
         <Col md="3" xs={12} sm={12}>
           <Bounce left>
         <p className='why-card'><AiFillDollarCircle className='why-icon'/><br/><br/>Reduce your costs</p>
         </Bounce>
         </Col>
         <Col md="3" xs={12} sm={12}>
           <Bounce left>
         <p className='why-card'><FiAlertTriangle className='why-icon'/><br/><br/>Hassle-free reporting alert systems</p>
         </Bounce>
         </Col>
         
         <Col md="1"/>
       
          </Row>

          <Row style={{margin:'0px'}}>
         
         <Col md="2"/>
       
         <Col md="3" xs={12} sm={12}>
           <Bounce right>
         <p className='why-card' ><BsGraphUp className='why-icon' /><br/><br/>Visible Improvement in your cash flow graph</p>
         </Bounce>
         </Col>
         <Col md="3" xs={12} sm={12}>
           <Bounce right>
         <p className='why-card'><BiTimeFive className='why-icon'/><br/><br/>Additional time available to grow your business</p>
         </Bounce>
         </Col>
         <Col md="3" xs={12} sm={12}>
           <Bounce right>
         <p className='why-card'> <BsExclamationOctagonFill className='why-icon'/><br/><br/>No challenges in transparency</p>
         </Bounce>
         </Col>
         <Col md="1"/>
      
          </Row>
          <br/>
          {/* <h4 align="center" className='h-heading'>Reliability, Scalability & Security</h4>
          <Container>
          <Row style={{margin:'0px'}}>
              
              <Col md="12" xs={12} sm={6}>
              <Tabs activeTab="1" ulClassName="" activityClassName="bg-info" className='tabs-rss'>
            <Tab title="Cloud Based" className="col-md-3 col-xs-12 col-sm-12" >
                <div className="mt-3">
                   <p className='tab-con'>The OBO HEALTH platform is deployed on AWS best in-cloud platform offering unparalleled uptime, scalability, and security – 24 x 7 x 365. Zero server mainenance, zero software installs, zero headache
                   </p>
                </div>
            </Tab>
            <Tab title="Third Party Integration" className="col-md-3 col-xs-12 col-sm-12">
                <div className="mt-3">
                  <p className='tab-con'>Our partnership with other apis enables us to seamlessly integrate with your existing EMR ,PMS , AND EHR solution to share healthcare data via a single, easy to use API.</p> 
                </div>
            </Tab>
            <Tab title="HIPPA Certified" className="col-md-3 col-xs-12 col-sm-12">
                <div className="mt-3">
                 <p className='tab-con'>OBO follows HIPAA Standard from the initial state of the product from the idea to launch . HIPAA compliant obo software for healthcare organizations that includes all the necessary privacy and security safeguards to meet the requirements of HIPAA.</p>
                </div>
            </Tab>
        </Tabs>
              </Col>
            
          </Row>
          </Container>
          <br/><br/> */}
          <div>
          <WeFollow></WeFollow>
          </div><br/><br/>
       {/* <h4 className='h-heading' align="center">What we heard from our customers</h4> */}
       {/* <br/> */}
       {/* <Container>
         <Row style={{margin:'0px'}}>
           <Col md={1}/>
          <Col md={5} xs={12} sm={12}>
            <br/><br/><br/>
            <p className='c-content'>Happy Customers!</p>
            <p className='c-heading'>We give our best to make<br/>you happy!</p>
            <p className='c-content'>Hear what our customers says about us!</p>
          </Col> 
          <Col md={5} xs={12} sm={12}>
          <Carousel
         infiniteLoop={true}
         autoPlay={true}
         showStatus={false}
         showArrows={false}
        >
          <div className='h-carousel'>
           <Avatar src={albert} round={true} size={80} className='c-img'/> 
           <h3 className='c-heading'>Alex</h3>
           <p className='c-content'>"Every feature in obo products make me wow! All the products really valuable to the business."</p>
          </div>
          <div className='h-carousel'>
           <Avatar src={albert} round={true} size={80} className='c-img'/> 
           
           <h3 className='c-heading'>Naresh</h3>
           <p className='c-content'>"One of the few companies which acknowledges and celebrates people and their work."  </p>
          </div>
          </Carousel><br/>
          </Col> 
          <Col md={1}/>
          </Row>
       </Container> */}<br/><br/>
      <Container>
          <Row style={{margin:'0px'}}>
      <Col md={12} xs={12} sm={12}>
      <img src={tech} style={{width:'100%'}} />
        </Col>
        </Row>
          </Container><br/><br/>
          <h4 align="center" className='h-heading'>Featured content</h4><br/><br/>
          <Container>
         <Row style={{margin:'0px'}} >
            <Col md="4" xs={12} sm={12}>
             <Card className='h-cards'>
            <Card.Img variant="top" src={fe1} className='f-img'/>
            <Card.Body>
                <Card.Title style={{fontWeight:'bold',lineHeight:'30px'}}>SUCCESS IN PROACTIVE DENIALS MANAGEMENT AND PREVENTION</Card.Title>
                <Link to='/features/denial-management' className='link'><p className='card-learnmore'>Learn More&nbsp;&nbsp;<BsFillArrowUpRightSquareFill className='learnmore-icon'/></p></Link>
            </Card.Body>
            </Card>
            </Col>
            <Col md="4" xs={12} sm={12}>
             <Card className='h-cards'>
            <Card.Img variant="top" src={trends} className='f-img'/>
            <Card.Body>
                <Card.Title style={{fontWeight:'bold',lineHeight:'30px'}}>KEY TRENDS IN MEDICAL BILLING PRACTICE</Card.Title>
                <Link to='/features/medical-billing' className='link'><p className='card-learnmore'>Learn More&nbsp;&nbsp;<BsFillArrowUpRightSquareFill className='learnmore-icon'/></p></Link>
            </Card.Body>
            </Card>
            </Col><br></br>
            <Col md="4" xs={12} sm={12}>
             <Card className='h-cards' >
            <Card.Img variant="top" src={f3} className='f-img'/>
            <Card.Body>
                <Card.Title style={{fontWeight:'bold',lineHeight:'30px'}}>CHALLENGES,TRENDS AND DISRUPTION IN NEXT FIVE YEARS IN RCM INDUSTRY</Card.Title>
                <Link to='/features/RCM' className='link'><p className='card-learnmore'>Learn More&nbsp;&nbsp;<BsFillArrowUpRightSquareFill className='learnmore-icon'/></p></Link>
            </Card.Body>
            </Card>
            </Col>
           
         </Row><br/>
         </Container>
         <br/><br/>
         <Container>
      <h4 className="h-heading" align="center">Empowering to scale, sustain and flourish with our Deep Thinking AI infused solutions</h4>
      <Link to='/contact-us' className='link'><p className='getdemo-center' align="center">Get Demo</p></Link>
      </Container>

      </div>
    )
  }
}
export default Home
