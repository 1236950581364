import React, { Component } from 'react'
import '../../Styles/products.css'
import {Row,Col, Container} from 'react-bootstrap'
import {MdUpcoming} from 'react-icons/md'
import {BsFillArrowRightCircleFill,BsFillArrowUpRightSquareFill,BsFillCheckCircleFill} from 'react-icons/bs'
import home from '../../Images/home.svg'
import side1 from '../../Images/side1.svg'
import outcome from '../../Images/outcome.PNG'
import video2 from '../../Images/video2.mp4'
import {BiVideo} from 'react-icons/bi'
import HoverVideoPlayer from 'react-hover-video-player'
import {Carousel} from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Link} from 'react-router-dom'
import ReactOwlCarousel from 'react-owl-carousel'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Navbar from '../Navbar'
export class Radiology extends Component {
  componentDidMount(){
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
         <Navbar color="white" textColor="black" shadow="0 2px 5px rgba(0,0,0,.2)"/>
          <h4 className='p-heading'>Radiology</h4>
          <p className='p-content'>OBO's - Radiology Platform has been our initial successful product</p>
          <Row style={{margin:'0px'}}>
              <Col md="4"></Col>
              <Col md="2" xs={6} sm={6}>
              <Link to='/contact-us' className='link'><p align="center" className='p-getdemo'>Get Demo</p></Link>
              </Col>
              <Col md="2" xs={6} sm={6}>
              <Link to='/start-trial' className='link'> <p align="center" className='p-starttrial'>Start Trial&nbsp;&nbsp;&nbsp;<BsFillArrowRightCircleFill className='starttrial-icon'/></p></Link>
              </Col>
              <Col md="4"></Col>
          </Row><br></br>

          <Row style={{margin:'0px'}}>
              <Col md="2">
                  <img src={side1} className='p-img'/>
              </Col>
              <Col md="8">
              <Link to='/contact-us' className='link'>
              <HoverVideoPlayer
                // videoSrc={video2}
                className="product-video"
                pausedOverlay={
                   <div className="product-thumbnail">
                    <p className="watch-product" align="center">Watch Our Product&nbsp;&nbsp;<BiVideo className="video-icon"/></p>
                    </div>  
                }
                loadingOverlay={
                    <div className="c-loading-spinner-overlay">Loading...</div>
                }
                restartOnPaused
                />
                </Link>
              </Col>
              <Col md="2">
                  <img src={home} className='p-img'/>
              </Col>
          </Row>
         <br/><br/>
           <h4 className='p-keyf'>Key Features</h4>
           <br/><br/>
           <Container>
          <ReactOwlCarousel
          items={1} 
          className="owl-theme" 
          loop={true}
          nav 
          dots={false}
          autoplay={true}
          autoplayTimeout={3000}
          
        >
          <div className='rd-carousel'>
            <Row style={{margin:'0px'}}>
              {/* <Col md={6} xs={12} sm={12} >
              <img src="https://m.media-amazon.com/images/I/61peIdz-C2L._SL1024_.jpg" className='keyf-img'/>
              </Col><br/> */}
              <Col md={12} xs={12} sm={12}>
                <p className='p-keyf-heading'>Prevent Denials with In-built Scrubbing System</p>
                <p className='p-keyf-content'>1. 17000+ Exhaustive rules for several insurance to identify potential denials.<br/>
                2.Auto updation of new rules<br/>3.Missed revenue opportunities Identifiers<br/>4.MIPS & MACRA in-built engine

                </p>
              </Col>
            </Row>
          </div>
          <div className='rd-carousel'>
          <Row style={{margin:'0px'}}>
       
              {/* <Col md="6" xs={12} sm={12}>
              <img src="https://m.media-amazon.com/images/I/61peIdz-C2L._SL1024_.jpg" className='keyf-img'/>
              </Col> */}
              <Col md="12" xs={12} sm={12}>
                <p className='p-keyf-heading'>Developed with latest Machine Learning Algorithms</p>
                <p className='p-keyf-content'>
                1.Enables continuous improvement areas for every individual<br/>2.Pro-actively identifies possible errors at an individual level on a real-time basis<br/>3.Self-Correction and Self-Learning enables for quality to be the best the 1st time.
   
                </p>
              </Col>
           
            </Row>
          </div>
          <div className='rd-carousel'>
          <Row style={{margin:'0px'}}>
        
              {/* <Col md={6} xs={12} sm={12}>
              <img src="https://m.media-amazon.com/images/I/61peIdz-C2L._SL1024_.jpg" className='keyf-img'/>
              </Col> */}
              <Col md={12} sm={12} xs={12}>
                <p className='p-keyf-heading'>Data-Analytics</p>
                <p className='p-keyf-content'>
                1.Integrated Data-Analytics platform helps business thrive for success<br/>2.Real-Time Alert systems for potential threats to a business.
                </p>
              </Col>
             
            </Row>
          </div>
          </ReactOwlCarousel>
          </Container><br/><br/>
          <h4 className='p-keyf'>Features</h4>
          <br/>
          <Container>
          <Row style={{margin:'0px'}}>
          <Col md="1"></Col>
              <Col md="5" xs={12} sm={6}>   
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Auto Coding & Demo Verification application</p>
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Agile for client customization</p>
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;In-built Scrubbing System with several thousand rules to predict and prevent denials proactively</p>
                <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Real-Time Access to progress and intra-application communication system production, Quality, and Inventory Reconciliation</p>
              </Col>
              <Col md="5" xs={12} sm={6}>
              
              <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;NLP - Natural Language Processing Algorithms auto predict the CPT, ICD-10, corresponding PQRS, and appropriate modifiers</p>
              <p className='p-keyf-content'> <BsFillCheckCircleFill className='feature-tick'/>&nbsp;&nbsp;Machine Learning Intelligence to mentor the employee's quality performance</p>
              </Col>
              <Col md="1"></Col>
          </Row>
          </Container>
          <br/>
          <br/>
          <h4 className='p-keyf'>Outcomes</h4><br/>
          <Container>
          <Row style={{margin:'0px'}}>
         
          <Col md="6">
            <p className="p-outcome">Improved collections and enhanced Daily Cash Flow</p>
            <p className="p-outcome">Reduction in Coding Denials</p>
            <p className="p-outcome">Productivity Improvement by 30% to 55%</p>
            <p className="p-outcome">Quality can be set aside on an Auto-Pilot mode</p>
            <p className="p-outcome">Critical–Key Performance Indicators to evaluate the Health of the Process</p>
            <p className="p-outcome">Transparency and Real-Time Performance status</p>
          </Col>
          <Col md="6">
        <img src={outcome} className="outcome-img"/>
          </Col>
          </Row>
          </Container>
      </div>
    )
  }
}

export default Radiology