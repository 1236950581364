import React, { Component } from 'react'
import { Row,Col,Container,Card } from 'react-bootstrap'
import { Button, Input, Label } from 'reactstrap'
import {MdCall} from 'react-icons/md'
import {RiChat2Line} from 'react-icons/ri'

import '../../Styles/company.css'
import Navbar from '../Navbar'
import '../../Styles/getdemo.css'
export class GetDemo extends Component {
  componentDidMount(){
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>

<Navbar color="#041b3d" textColor="white"/>
          <div className='getdemo-top'>
             
          <Container>
                <Row style={{margin:'0px'}}>
                 <Col md="6" xs={12} sm={12}>
                   <p className='h-heading'>Get in touch with us!</p>
                   <Row style={{margin:'0px'}}>
                   <Col md="6" xs={6} sm={6}>
                   <p align="center" className='call-getdemo' style={{backgroundColor:'#ceddff',color:'#1862ff',height:"22%",align:"center"}}><MdCall className='getdemo-icon'/></p>
                    <b>Call Us</b>
                    <p>Question about our product or pricing?Call for support.</p>
                    <p>(410)999-0303</p>
                    </Col>
                    <Col md="6" xs={6} sm={6}>
                   <p align="center" className='call-getdemo' style={{backgroundColor:'#fbcae4',color:'#f5118a',height:"22%",align:"center"}}><RiChat2Line className='getdemo-icon'/></p>
                    <b>Chat Us</b>
                    <p>Our support will help you <br/>from 9am to 5pm EST.</p>
                    
                    </Col>
                    </Row><br/>
                   </Col>  
                 
                   <Col md='6' xs={12} sm={12}>
                     <Card className='card-getdemo'> 
                                {/* <div className='card-getdemo'> */}
                            <p className='formula-head'>Fill out the form and we'll be in touch as soon as possible!</p> <br/>
                            <Row style={{margin:'0px'}}>
                            <Col md="6" xs={12} sm={6}>
                              <input className='gd-input' placeholder='Your Name'></input>
                            </Col>  
                            <Col md="6" xs={12} sm={6}>
                            <input className='gd-input' placeholder='Your Email'></input>
                            </Col>  
                              
                            </Row>  
                            <Row style={{margin:'0px'}}>
                            <Col md="4" xs={12} sm={6}>
                            <select type="select" className='gd-input' placeholder='Who are you?'>
                              <option hidden style={{color:'grey'}}>Who are you?</option>
                                <option>Group Physician</option>
                                <option>Hospital</option>
                                <option>Billing Company</option>
                                <option>Independent Physician</option>
                                <option>ACO</option>
                                <option>IPA</option>
                            </select>
                            </Col>  
                            <Col md="8" xs={12} sm={6}>
                            <input className='gd-input' placeholder='What is your monthly patient visits?'></input>
                            </Col>  
                              
                            </Row>  
                            <Row style={{margin:'0px'}}>
                            <Col md="12" xs={12} sm={6}>
                            <input className='gd-input' placeholder='What is your monthly charges(in $)?'></input>
                            </Col>   
                              
                            </Row>     
                            <Row style={{margin:'0px'}}>
                            <Col md="6" xs={12} sm={6}>
                            <input className='gd-input' placeholder='State'></input>
                            </Col>  
                            <Col md="6" xs={12} sm={6}>
                            <input className='gd-input' placeholder='Phone Number'></input>
                            </Col>  
                              
                            </Row>   
                            <Row style={{margin:'0px'}}>
                            <Col md="12" xs={12} sm={12}>
                            <textarea placeholder='What is your current pain points?' rows={4} className='gd-textarea'/> 
                            </Col>  
                            </Row>  
                            <button className='schedule-demobtn'>Send Message</button>    
                      </Card>
                            </Col>
                          
                      
                  </Row>
              </Container>
          </div>
         
      </div>
    )
  }
}

export default GetDemo;