import React, { Component } from 'react'
import {Row,Col, Container} from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import logo from '../../Images/blank.png'
import {BsFillArrowUpRightSquareFill} from 'react-icons/bs'
import { Link } from 'react-router-dom';
import side1 from '../../Images/side1.svg';
import denial from '../../Images/denial.PNG'
import ehr from '../../Images/ehr.jpeg'
export class Rendermenu extends Component {
  render() {
    return (
      <div>
        {/* <Col md="2"> */}
        {/* <img src={side1} className='p-img'/> */}
        
           <Tabs>
        <TabList>
          <Tab>
            <p className='vertical-menu'>DENIAL AND ARS PLATFORM</p>
          </Tab>
          <Tab>
            <p className='vertical-menu'>RADIOLOGY</p>
          </Tab>
          <Tab>
            <p className='vertical-menu'>EHR</p>
          </Tab>
          <Tab>
            <p className='vertical-menu'>E/R,ED</p>
          </Tab>
          <Tab>
            <p className='vertical-menu'>PMS</p>
          </Tab>
        </TabList>
        <TabPanel>
        <Row style={{margin:'0px'}}>
                            <Col md="12" xs={12} sm={12}>
                            <img src={denial} className="product-img" />
                            </Col>
                </Row>
                <Container>
                <Row style={{margin:'0px'}}>
                  
                    <Col md="6" xs={6} sm={6}>
                    <Link to='/contact-us' className='link'> <p className='h-getdemobtn'>Get Demo</p></Link>
                        </Col>
                    <Col md="6" xs={6} sm={6}>
                    <Link to='/products/denial-and-ars-platform' className='link'><p className='h-learnmore'>Learn More&nbsp;&nbsp;<BsFillArrowUpRightSquareFill className='learnmore-icon'/></p>
                    </Link>
                    </Col>
            
                </Row>
                </Container>
        </TabPanel>
        <TabPanel>
                <Row style={{margin:'0px'}}>
                            <Col md="12" xs={12} sm={12}>
                            <img src="https://m.media-amazon.com/images/I/61peIdz-C2L._SL1024_.jpg" className="product-img" />
                            </Col>
                </Row>
                <Container>
                <Row style={{margin:'0px'}}>
                  
                    <Col md="6" xs={6} sm={6}>
                    <Link to='/contact-us' className='link'>  <p className='h-getdemobtn'>Get Demo</p></Link>
                    </Col>
                    <Col md="6" xs={6} sm={6}>
                    <Link to='/products/radiology' className='link'><p className='h-learnmore'>Learn More&nbsp;&nbsp;<BsFillArrowUpRightSquareFill className='learnmore-icon'/></p>
                    </Link> 
                    </Col>
            
                </Row>
                </Container>
        </TabPanel>
        <TabPanel>
        <Row style={{margin:'0px'}}>
                            <Col md="12"  xs={12} sm={12}>
                            <img src={ehr} className="product-img" />
                            </Col>
                </Row>
                <Container>
                <Row style={{margin:'0px'}}>
                  
                    <Col md="6" xs={6} sm={6}>
                    <Link to='/contact-us' className='link'>  
                        <p className='h-getdemobtn'>Get Demo</p>
                    </Link>
                        </Col>
                    <Col md="6" xs={6} sm={6}>
                      <Link to='/products/ehr'>
                       <p className='h-learnmore'>Learn More&nbsp;&nbsp;<BsFillArrowUpRightSquareFill className='learnmore-icon'/></p>
                       </Link>
                        </Col>
            
                </Row>
                </Container>
        </TabPanel>
        <TabPanel>
        <Row style={{margin:'0px'}}>
                            <Col md="12"  xs={12} sm={12}>
                            <img src="https://m.media-amazon.com/images/I/61peIdz-C2L._SL1024_.jpg" className="product-img" />
                            </Col>
                </Row>
                <Container>
                <Row style={{margin:'0px'}}>
                  
                    <Col md="6" xs={6} sm={6}>
                    <Link to='/contact-us' className='link'>  <p className='h-getdemobtn'>Get Demo</p></Link>
                        </Col>
                    <Col md="6" xs={6} sm={6}>
                    <Link to='/products/er-ed' className='link'> <p className='h-learnmore'>Learn More&nbsp;&nbsp;<BsFillArrowUpRightSquareFill className='learnmore-icon'/></p></Link>
                        </Col>
            
                </Row>
                </Container>
        </TabPanel>
       
       
        <TabPanel>
        <Row style={{margin:'0px'}}>
                            <Col md="12"  xs={12} sm={12}>
                            <img src="https://thumbs.dreamstime.com/b/launching-soon-page-design-app-interface-smart-phones-vector-illustration-166943005.jpg" className="product-img" />
                            </Col>
                </Row>
        </TabPanel>
      </Tabs>
      </div>
    )
  }
}

export default Rendermenu